import "@fortawesome/fontawesome-free/css/all.css";
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { ja } from 'vuetify/locale'
import * as directives from 'vuetify/directives'
import * as components from 'vuetify/components'

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: "#DC2828",
    secondary: "#32A0D2",
    accent: "#FFE600",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    default: '#e0e0e0'
  },
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components,
  directives,
  theme: {
    options: { customProperties: true },
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    }
  },
  icons: {
    iconfont: "mdi",
  },
  locale: {
    locale: "ja",
    current: "ja",
    messages: { ja }
  },
  defaults: {
    VTextField: {
      density: 'compact'
    },
    VSelect: {
      density: 'compact'
    },
    VCardText: {
      style: {
        padding: '16px',
      },
    },
    VCardTitle: {
      style: {
        padding: '16px 16px 0px 16px',
      },
    },
    VRadioGroup: {
      inline: true,
      color: 'primary',
    },
    VCheckbox: {
      color: 'primary',
    },
    VChip: {
      variant: 'flat',
    },
    VExpansionPanels: {
      variant: 'accordion',
    }
  },
})