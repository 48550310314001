import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from '@/utils';

const routes = [
  {
    path: "/ip-restrictions",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "IpRestrictions",
        component: () => import("@/views/IpRestrictions/List/index.vue"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.ip_restrictions")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.ip_restrictions"),
            },
          ],
        },
      },
      {
        path: "create",
        name: "CreateIP",
        component: () => import("@/views/IpRestrictions/CreateAndEdit"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.ip_restrictions")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.ip_restrictions"),
              to: { name: "IpRestrictions" },
            },
            {
              text: "",
            },
          ],
        },
      },
      {
        path: "edit/:id",
        name: "EditIP",
        component: () => import("@/views/IpRestrictions/CreateAndEdit"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.ip_restrictions")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb() {
            const id = this.$route.params.id;
            return [
              {
                text: i18n.global.t("nav.ip_restrictions"),
                to: { name: "IpRestrictions" },
              },
              {
                text: `${i18n.global.t("label.id")}: ${id}`,
              },
            ];
          },
        },
      },
    ],
  },
];

export default routes;
