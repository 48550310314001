<template>
  <router-link
    class="text-decoration-none"
    :to="`/game-management/${id}/public-url`"
    :event="hasNext ? 'click' : ''"
    :disabled="!hasNext"
  >
    <div v-if="count > 0" class="d-flex">
      <v-chip small color="secondary" class="rounded-r-0 cursor-pointer">
        {{ $t("label.url") }}
      </v-chip>

      <v-chip
        color="secondary"
        variant="outlined"
        size="small"
        class="rounded-l-0 cursor-pointer"
        v-bind:class="{ 'background-white': background }"
      >
        {{ count }}
      </v-chip>
    </div>

    <div v-else class="d-flex">
      <v-chip size="small" variant="outlined" class="rounded-r-0 cursor-pointer"> URL </v-chip>

      <v-chip variant="outlined" size="small" class="rounded-l-0 cursor-pointer">
        {{ count }}
      </v-chip>
    </div>
  </router-link>
</template>
<script>
export default {
  name: "PGUrlNumber",
  props: {
    count: { type: Number, default: 0 },
    background: { type: Boolean, default: true },
    id: { type: String, default: null },
    hasNext: { type: Boolean, default: true },
  },
};
</script>

<style>
.v-chip.v-chip--outlined.v-chip.v-chip.background-white {
  background-color: #ffffff !important;
}
</style>
