<template>
  <span class="p-score-chips d-flex flex-column-gap-4 flex-row-gap-2 flex-wrap">
    <span class="d-flex flex-nowrap">
      <v-chip
        size="small"
        color="green"
        dark
        class="rounded-r-0"
      >
        クリア条件 </v-chip
      ><v-chip
        color="green"
        variant="outlined"
        size="small"
        class="rounded-l-0"
      >
        {{ copper.toLocaleString() }} 点
      </v-chip>
    </span>
    <span>
      <v-chip color="accent" class="darken-1 text--primary" size="small"
        ><v-icon location="left" size="small">mdi-medal</v-icon>金: {{ gold.toLocaleString() }} 点</v-chip
      >
    </span>
    <span>
      <v-chip color="blue-grey" class="lighten-3" size="small"
        ><v-icon location="left" size="small">mdi-medal</v-icon>銀: {{ silver.toLocaleString() }} 点</v-chip
      >
    </span>
    <span>
      <v-chip color="brown" class="lighten-3" size="small"
        ><v-icon location="left" size="small">mdi-medal</v-icon>銅 : {{ copper.toLocaleString() }} 点</v-chip
      >
    </span>
  </span>
</template>

<script>
export default {
  name: "PGScoreChips",
  props: {
    gold: { type: Number, default: null, required: true },
    silver: { type: Number, default: null, required: true },
    copper: { type: Number, default: null, required: true },
  },
};
</script>

<style lang="scss" scoped>
</style>
