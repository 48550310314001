<template>
  <div
    v-if="items?.length > 0"
    class="p-alert-group d-flex flex-column flex-gap-2"
  >
    <p-alert
      v-for="(item, index) in items"
      :key="index"
      :type="item?.type || 'error'"
      class="w-fit-content"
      :class="{
        'mb-0': items.length - 1 !== index,
        'mb-6': items.length - 1 === index,
      }"
      :style="{
        gap: items.index ? 'white' : 'gray'
      }"
      v-bind="item?.attrs"
      :closable="closable || item?.type?.attrs?.closable"
      >
    {{ item.message }}
    </p-alert>
  </div>
</template>

<script>
export default {
  name: "PGAlertGroup",
  props: {
    items: {
      type: Array,
      // default: () => [
      //   {
      //     type: 'error',
      //     message: 'error message',
      //     attrs: [
      //       {
      //         closable: false,
      //       }
      //     ],
      //   },
      // ],
      required: true,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>