<template>
  <router-link
    class="text-decoration-none"
    :to="`/game-management/${id}/visibility-setting`"
    :event="hasNext ? 'click' : ''"
    :disabled="!hasNext"
  >
    <div v-if="status == PERIOD_STATUS.PUBLIC" class="d-flex">
      <v-chip size="small" color="secondary" class="rounded-r-0 cursor-pointer">
        {{ $t("label.tissue") }}
      </v-chip>

      <v-chip
        color="secondary"
        variant="outlined"
        size="small"
        class="rounded-l-0 cursor-pointer"
        v-bind:class="{ 'background-white': background }"
        style="border-color: initial !important;"
      >
        {{ $t("label.now_open") }}
      </v-chip>
    </div>

    <div v-else class="d-flex">
      <v-chip size="small" variant="outlined" class="rounded-r-0 cursor-pointer">
        <span class="text-grey-darken-3">
          {{ $t("label.tissue") }}
        </span>
      </v-chip>

      <v-chip size="small" variant="outlined" class="rounded-l-0 cursor-pointer">
        <v-icon location="left" size="small" class="icon-color-lock"> mdi-lock</v-icon>
        <span class="text-grey-darken-3">
          {{ $t("label.private") }}
        </span>
      </v-chip>
    </div>
  </router-link>
</template>
<script>
import { CONSTANTS } from "@/utils";
export default {
  name: "PGGameStatus",
  props: {
    status: { type: Number, default: CONSTANTS.PERIOD_STATUS.PRIVATE },
    background: { type: Boolean, default: true },
    id: { type: String, default: null },
    hasNext: { type: Boolean, default: true },
  },
  data() {
    return {
      PERIOD_STATUS: CONSTANTS.PERIOD_STATUS,
    };
  },
};
</script>

<style>
.v-chip.v-chip--outlined.v-chip.v-chip.background-white {
  background-color: #ffffff !important;
}
</style>
