<template>
  <div>
    <v-list :opened="openItem">
      <ListGroup :items="items" />
    </v-list>
  </div>
</template>
<script>
import ListGroup from "./ListGroup.vue";

const paramsList = ["areaId", "courseId", "stageId"];

export default {
  name: "PGTreeview",

  props: {
    items: { type: Array, default: () => [] },
  },

  components: {
    ListGroup,
  },

  data: () => ({
    openItem: [],
  }),

  watch: {
    "$route.params": {
      handler: async function (params) {
        this.openItem = [];
        paramsList.forEach((item) => {
          const value = params[item];
          const pre = this.openItem?.[this.openItem.length - 1];
          if (value) {
            this.openItem.push((pre ? pre + "-" : "") + value);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
