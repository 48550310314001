const COLLECTION_FIREBASE = {
	COMPANY: "Company_Collection",
	USERS: "users",
};
const AREA_HEADER = [
	"【エリア】名 [20]",
	"【エリア】順番",
	"【エリア】非公開/公開/期間公開（1/2/3)",
	"【エリア】公開期間開始日",
	"【エリア】公開期間開始時間",
	"【エリア】公開期間終了日",
	"【エリア】公開期間終了時間",
];
const COURCE_HEADER = [
	"【コース】名 [20]",
	"【コース】順番",
	"【コース】非公開/公開/期間公開（1/2/3)",
	"【コース】公開日",
	"【コース】公開時間	",
	"【コース】公開終了日",
	"【コース】公開終了時間",
	"【コース】開放条件(前のコースクリア：0/常に解放：1)",
	"【コース】公開URL(0/1)",
];
const DORAKUE_COURSE = [
	"【コース】名 [20]",
	"【コース】順番",
	"【コース】非公開/公開/期間公開（1/2/3)",
	"【コース】公開期間開始日",
	"【コース】公開期間開始時間",
	"【コース】公開期間終了日",
	"【コース】公開期間終了時間",
	"【コース】開放条件(前のコースクリア：0/常に解放：1)",
	"【コース】公開URL(0/1)",
];
const DORAKUE_STAGE = [
	"【ステージ】名[20]",
	"【ステージ】順番",
	"【ステージ】開放条件(前のステージ全クリア：0/常に解放：1)",
	"【ステージ】非公開/公開/期間公開（1/2/3)",
	"【ステージ】公開期間開始日",
	"【ステージ】公開期間開始時間",
	"【ステージ】公開期間終了日",
	"【ステージ】公開期間終了時間",
	"【ステージ】公開URL（無効：0/有効：1)",
];
const DORAKUE_QUESTION = [
	"【問題】タイトル[20]",
	"【問題】順番",
	"【問題】非公開/公開/期間公開（1/2/3)",
	"【問題】問題/公開期間開始日",
	"【問題】問題/公開期間開始時間",
	"【問題】問題/公開期間終了日",
	"【問題】問題/公開期間終了時間",
	"【問題】問題文[28]",
	"【問題】選択肢1[20]",
	"【問題】解説見出し1[20]",
	"【問題】解説文1[50]",
	"【問題】不正解/正解1(0/1)",
	"【問題】選択肢2[20]",
	"【問題】解説見出し1[20]",
	"【問題】解説文2[50]",
	"【問題】不正解/正解2(0/1)",
	"【問題】選択肢3[20]",
	"【問題】解説見出し3[20]",
	"【問題】解説文3[50]",
	"【問題】不正解/正解3(0/1)",
];
// const URL_HEADER = [
// 	"【URL】コース公開URL",
// 	"【URL】ステージ公開URL",
// ]
const STAGE_HEADER = [
	"【ステージ】開放条件(前のステージ全クリア：0/常に解放：1)",
	"【ステージ】順番",
	"【ステージ】非公開/公開/期間公開（1/2/3)",
	"【ステージ】公開日",
	"【ステージ】公開時間",
	"【ステージ】公開終了日	",
	"【ステージ】公開終了時間",
	"【ステージ】公開URL（無効：0/有効：1）",
];
const CONSTANTS = {
	TOKEN: "token",
	AUTH_STORAGE: "auth_storage",
	REFRESH_TOKEN: "refreshToken",
	USER_SELECT: 'user_selected',
	USER_LIST: 'user_list',
	COMPANY_ID: "company_id",
	VERIFY_AT: "verify_at",
	EMAIL :  "email" ,
	GENERATE: "generateQuestion",
	IMG_GENERATE: "img_generateQuestion",
	SECRETKEY : "6w9z$C&F)J@McQfT",
	MOON: [
		"01",
		"02",
		"03",
		"04",
		"05",
		"06",
		"07",
		"08",
		"09",
		"10",
		"11",
		"12",
	],

	MONTH:[
		{
			text: '01',
			value: '01',
			disabled: false,
		},
		{
			text: '02',
			value: '02',
			disabled: false,
		},
		{
			text: '03',
			value: '03',
			disabled: false,
		},
		{
			text: '04',
			value: '04',
			disabled: false,
		},
		{
			text: '05',
			value: '05',
			disabled: false,
		},
		{
			text: '06',
			value: '06',
			disabled: false,
		},
		{
			text: '07',
			value: '07',
			disabled: false,
		},
		{
			text: '08',
			value: '08',
			disabled: false,
		},
		{
			text: '09',
			value: '09',
			disabled: false,
		},
		{
			text: '10',
			value: '10',
			disabled: false,
		},
		{
			text: '11',
			value: '11',
			disabled: false,
		},
		{
			text: '12',
			value: '12',
			disabled: false,
		},
	],

	ROLES: [
		{ text: "一般", value: 0 },
		{ text: "ゲーム管理者", value: 1 },
		{ text: "管理者", value: 2 },
		{ text: "オーナー", value: 3 },
	],

	TYPE_ROLE: {
		GENERAL: 0,
		GAME_ADMIN: 1,
		ADMIN: 2,
		OWNER: 3,
	},

	AVATAR_TYPE: {
		FRAME: 0,
		CHARACTER: 1,
		BACKGROUND: 2,
	},

	PLAN_TYPE: {
		FREE_TRIAL: 1,
		FREE_SHARE: 2,
		STANDARD: 3,
		STANDARD_1: 3,
		STANDARD_2: 4,
		PLANTINUM: 5,
		PLANTINUM_1: 5,
		PLANTINUM_2: 6,
		BUSINESS: 7,
	},
	
	HANDLE_CROP_BACKGROUND: {
		width: 2048,
		height: 1024,
		scaleX: 1,
		scaleY: 1,
	},

	HANDLE_CROP_BACKGROUND_INGAME: {
		width: 1290,
		height: 1290,
		scaleX: 1,
		scaleY: 1,
	},

	HANDLE_CROP_ICON: {
		width: 640,
		height: 640,
		scaleX: 1,
		scaleY: 1,
	},
	HANDLE_CROP_AVATAR: {
		width: 640,
		height: 640,
		scaleX: 1,
		scaleY: 1,
	},
	HANDLE_CROP_QUETIONS_DOUSURU: {
		width: 2200,
		height: 1100,
		scaleX: 1,
		scaleY: 1,
	},
	HANDLE_CROP_IMG_DORAKUE: {
        width: 1154,
        height: 700,
        scaleX: 1,
        scaleY: 1,
    },
	CATEGORY_GAME: [
		{ text: "PoiPoi", value: 1 },
		{ text: "どんめも", value: 2 },
		{ text: "カートでGO!", value: 3 },
		{ text: "ブランドぴったん", value: 4 },
		{ text: "クエストクラフト", value: 5 },
		{ text: "DOUSURU?", value: 6 },
	],

	RATING_DISPLAY_TIME: [
        { text: "週間", value: 1 },
        { text: "月間", value: 2 },
        { text: "総合", value: 3 },
    ],

	IMAGE_SIZE_INFO: [
		{ type: 1, text: "※ 画像サイズ:   横1537 x 縦615px 以下" },
		{ type: 2, text: "※ 画像サイズ:   横778 x 縦989px 以下" },
		{ type: 3, text: "※ 画像サイズ:   横1024 x 縦800px 以下" },
		{ type: 4, text: "※ 画像サイズ:   横1024 x 縦584px 以下" },
		{ type: 5, text: "※ 画像サイズ:   横1050 x 縦648px 以下" },
		{ type: 6, text: "※ 画像サイズ:   横1537 x 縦615px 以下" },
	],

	ANSWER_TIME: [
		{ text: "♾️", value: null },
		{ text: "3秒", value: 3 },
		{ text: "5秒", value: 5 },
		{ text: "7秒", value: 7 },
		{ text: "10秒", value: 10 },
		{ text: "15秒", value: 15 },
		{ text: "30秒", value: 30 },
	],
	DOUSURU_TIME: [
		{ text: "制限なし", value: null },
		{ text: "3秒", value: 3 },
		{ text: "5秒", value: 5 },
		{ text: "7秒", value: 7 },
		{ text: "10秒", value: 10 },
		{ text: "15秒", value: 15 },
		{ text: "30秒", value: 30 },
	],
	START_TIME: [
		{ text: "なし", value: null },
		{ text: "1月", value: 1 },
		{ text: "2月", value: 2 },
		{ text: "3月", value: 3 },
		{ text: "4月", value: 4 },
		{ text: "5月", value: 5 },
		{ text: "6月", value: 6 },
		{ text: "7月", value: 7 },
		{ text: "8月", value: 8 },
		{ text: "9月", value: 9 },
		{ text: "10月", value: 10 },
		{ text: "11月", value: 11 },
		{ text: "12月", value: 12 },
	],
	ANIMATION: [
		{ text: "グラデーション赤", value: 1 },
		{ text: "ウェーブ青", value: 2 },
		{ text: "シャッター", value: 3 },
		{ text: "カメラ", value: 4 },
	],
	ANIMATION_SINGLE_MODE: [
		{ text: "グラデーション赤", value: 1 },
		{ text: "ウェーブ青", value: 2 },
		{ text: "シャッター", value: 3 },
		{ text: "カメラ", value: 4 },
		{ text: "VS", value: 5 },
	],
	ANSWER_LIST: [
		{ text: "すべて", value: null },
		{ text: "あり", value: true },
		{ text: "なし", value: false },
	],

	GAME_TYPE_SIZES: {
		1: { width: 1537, height: 615 },
		2: { width: 778, height: 989 },
		3: { width: 1024, height: 800 },
		4: { width: 1024, height: 584 },
		5: { width: 1050, height: 648 },
		6: { width: 1537, height: 615 },
	},

	GAME: {
		POIPOI: "poiPoi",
		DONMEMO: "donmemo",
		CARTDE_GO: "cartdego",
		BRAND_PITTAN: "brandpittan",
		DORAKUE: "dorakue",
		DOUSURU: "dousuru",
	},

	GAME_DORAKUE_EXPORT_HEADER: [
		...AREA_HEADER,
		...DORAKUE_COURSE,
		...DORAKUE_STAGE,
		...DORAKUE_QUESTION,
		// ...URL_HEADER,
	],

	GAME_CARTDE_GO_HEADER: [
		...COURCE_HEADER,
		"【ステージ】名[20]",
		...STAGE_HEADER,
		"【問題】順番",
		"【問題】非公開/公開/期間公開（1/2/3)",
		"【問題】公開日",
		"【問題】公開時間",
		"【問題】公開終了日	",
		"【問題】公開終了時間",
		"【問題】問題文[40]",
		"【問題】解説見出し1[20]",
		"【問題】解説文1[50]",
		"【問題】不正解/正解1(0/1)",
		"【問題】解説見出し2[20]",
		"【問題】解説文2[50]",
		"【問題】不正解/正解2(0/1)",
		// ...URL_HEADER,
	],
	GAME_POIPOI_HEADER: [
		...COURCE_HEADER,
		...STAGE_HEADER,
		"【問題】問題文[50]",
	],
	GAME_DOMEMO_HEADER: [
		...COURCE_HEADER,
		"【ステージ】名[20]",
		...STAGE_HEADER,
		"【問題】順番",
		"【問題】問題文[40]",
		"【問題】解説見出し[20]",
		"【問題】解説文[100]",
	],
	GAME_BRAND_PITTAN_HEADER: [
		...COURCE_HEADER,
		...STAGE_HEADER,
		"【ステージ】問題（0：画像/1：テキスト）",
		"【問題】問題文[26]",
		"【問題】リール一No.",
	
	],
	END_URL_HEADER : [
		"【URL】コース公開URL",
		"【URL】ステージ公開URL",
	],
	QUEST_CRAFT_EXPALIN: [
		["項目", "値", "説明"],
		["【エリア】名 [20]", "", "20文字まで"],
		["【エリア】順番", "", "エリアの順番"],
		["【エリア】非公開/公開/期間公開(1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【コース】名 [20]", "", "20文字まで"],
		["【コース】順番", "", "コースの順番"],
		["【コース】非公開/公開/期間公開(1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【コース】開放条件(前のコースクリア：0/常に解放：1)", "0", "同じエリア内のこれより前のコースすべてをクリア"],
		["", "1", "常に解放"],
		["【コース】公開URL(0/1)", "0", "無効にする"],
		["", "1", "有効にする"],
		["【ステージ】名[20]", "", "20文字まで"],
		["【ステージ】順番", "", "ステージの順番"],
		["【ステージ】開放条件(前のステージ全クリア：0/常に解放：1)", "0", "同じコース内のこれより前のステージすべてをクリア"],
		["", "1", "常に解放"],
		["【ステージ】非公開/公開/期間公開(1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【ステージ】公開URL(無効：0/有効：1)", "0", "無効にする"],
		["", "1", "有効にする"],
		["【問題】タイトル[20]", "", "20文字まで"],
		["【問題】順番", "", "問題の順番"],
		["【問題】非公開/公開/期間公開(1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【問題】問題文[28]", "", "28文字まで"],
		["", "", ""],
		["【問題】選択肢1[20]", "", "20文字まで"],
		["【問題】解説見出し1[20]", "", "20文字まで"],
		["【問題】解説文1[50]", "", "50文字まで"],
		["【問題】不正解/正解1(0/1)", "0", "正解（正解を1個、不正解を2個登録してください。）"],
		["", "1", "不正解（正解を1個、不正解を2個登録してください。）"],
		["【問題】選択肢2[20]", "", "20文字まで"],
		["【問題】解説見出し1[20]", "", "20文字まで"],
		["【問題】解説文2[50]", "", "50文字まで"],
		["【問題】不正解/正解2(0/1)", "0", "正解（正解を1個、不正解を2個登録してください。）"],
		["", "1", "不正解（正解を1個、不正解を2個登録してください。）"],
		["【問題】選択肢3[20]", "", "20文字まで"],
		["【問題】解説見出し3[20]", "", "20文字まで"],
		["【問題】解説文3[50]", "", "50文字まで"],
		["【問題】不正解/正解3(0/1)", "0", "正解（正解を1個、不正解を2個登録してください。）"],
		["", "1", "不正解（正解を1個、不正解を2個登録してください。）"],
	],
	CARTDE_GO_EXPLAIN: [
		["項目", "値", "説明"],
		["【コース】名 [20]", "", "20文字まで"],
		["【コース】順番", "", "コースの順番"],
		["【コース】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【コース】開放条件(前のコースクリア：0/常に解放：1)", "0", "同じエリア内のこれより前のコースすべてをクリア"],
		["", "1", "常に解放"],
		["【コース】公開URL(0/1)", "0", "無効にする"],
		["", "1", "有効にする"],
		["【ステージ】名[20]", "", "20文字まで"],
		["【ステージ】開放条件(前のステージ全クリア：0/常に解放：1)", "0", "同じコース内のこれより前のステージすべてをクリア"],
		["", "1", "常に解放"],
		["【ステージ】順番", "", "ステージの順番"],
		["【ステージ】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【ステージ】公開URL（無効：0/有効：1）", "0", "無効にする"],
		["", "1", "有効にする"],
		["【問題】順番", "", "問題の順番"],
		["【問題】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【問題】問題文[40]", "", "40文字まで"],
		["【問題】解説見出し1[20]", "", "20文字まで"],
		["【問題】解説文1[50]", "", "50文字まで"],
		["【問題】不正解/正解1(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】解説見出し2[20]", "", "20文字まで"],
		["【問題】解説文2[50]", "", "50文字まで"],
		["【問題】不正解/正解2(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
	],
	POIPOI_EXPLAIN: [
		["項目", "値", "説明"],
		["【コース】名 [20]", "", "20文字まで"],
		["【コース】順番", "", "コースの順番"],
		["【コース】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【コース】開放条件(前のコースクリア：0/常に解放：1)", "0", "同じエリア内のこれより前のコースすべてをクリア"],
		["", "1", "常に解放"],
		["【コース】公開URL(0/1)", "0", "無効にする"],
		["", "1", "有効にする"],
		["【ステージ】開放条件(前のステージ全クリア：0/常に解放：1)", "0", "同じコース内のこれより前のステージすべてをクリア"],
		["", "1", "常に解放"],
		["【ステージ】順番", "", "ステージの順番"],
		["【ステージ】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【ステージ】公開URL（無効：0/有効：1）", "0", "無効にする"],
		["", "1", "有効にする"],
		["【問題】問題文[50]", "", "50文字まで"],
		["【問題】解説見出し1[20]", "", "20文字まで"],
		["【問題】解説文1[50]", "", "50文字まで"],
		["【問題】不正解/正解1(0/1)", "0", "正解"],
		["", "1", "不正解"],
		["【問題】解説見出し2[20]", "", "20文字まで"],
		["【問題】解説文2[50]", "", "50文字まで"],
		["【問題】不正解/正解2(0/1)", "0", "正解"],
		["", "1", "不正解"],
		["【問題】解説見出し3[20]", "", "20文字まで"],
		["【問題】解説文3[50]", "", "50文字まで"],
		["【問題】不正解/正解3(0/1)", "0", "正解"],
	],
	DONMEMO_EXPALIN: [
		["項目", "値", "説明"],
		["【コース】名 [20]", "", "20文字まで"],
		["【コース】順番", "", "コースの順番"],
		["【コース】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【コース】開放条件(前のコースクリア：0/常に解放：1)", "0", "同じエリア内のこれより前のコースすべてをクリア"],
		["", "1", "常に解放"],
		["【コース】公開URL(0/1)", "0", "無効にする"],
		["", "1", "有効にする"],
		["【ステージ】名[20]", "", "20文字まで"],
		["【ステージ】開放条件(前のステージ全クリア：0/常に解放：1)", "0", "同じコース内のこれより前のステージすべてをクリア"],
		["", "1", "常に解放"],
		["【ステージ】順番", "", "ステージの順番"],
		["【ステージ】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【ステージ】公開URL（無効：0/有効：1）", "0", "無効にする"],
		["", "1", "有効にする"],
		["【問題】順番", "", "問題の順番"],
		["【問題】問題文[40]", "", "40文字まで"],
		["【問題】解説見出し[20]", "", "20文字まで"],
		["【問題】解説文[100]", "", "100文字まで"],
		["【問題】不正解/正解1(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解2(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解3(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解4(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解5(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解6(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解7(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解8(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】不正解/正解9(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
	],
	BRAND_PITTAN_EXPLAIN: [
		["項目", "値", "説明"],
		["【コース】名 [20]", "", "20文字まで"],
		["【コース】順番", "", "コースの順番"],
		["【コース】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【コース】開放条件(前のコースクリア：0/常に解放：1)", "0", "同じエリア内のこれより前のコースすべてをクリア"],
		["", "1", "常に解放"],
		["【コース】公開URL(0/1)", "0", "無効にする"],
		["", "1", "有効にする"],
		["【ステージ】開放条件(前のステージ全クリア：0/常に解放：1)", "0", "同じコース内のこれより前のステージすべてをクリア"],
		["", "1", "常に解放"],
		["【ステージ】順番", "", "ステージの順番"],
		["【ステージ】非公開/公開/期間公開（1/2/3)", "1", "非公開"],
		["", "2", "公開"],
		["", "3", "期間公開"],
		["【ステージ】公開URL（無効：0/有効：1）", "0", "無効にする"],
		["", "1", "有効にする"],
		["【ステージ】問題（0：画像/1：テキスト）", "0", "画像"],
		["", "1", "テキスト"],
		["【問題】問題文[26]", "", "2６文字まで"],
		["【問題】リール一No.", "", "リールの順番"],
		["【問題】解説見出し1[20]", "", "20文字まで"],
		["【問題】解説文1[50]", "", "50文字まで"],
		["【問題】不正解/正解1(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】解説見出し2[20]", "", "20文字まで"],
		["【問題】解説文2[50]", "", "50文字まで"],
		["【問題】不正解/正解2(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
		["【問題】解説見出し3[20]", "", "20文字まで"],
		["【問題】解説文3[50]", "", "50文字まで"],
		["【問題】不正解/正解3(0/1)", "0", "不正解（正解を1個、不正解を1個登録してください。）"],
		["", "1", "正解（正解を1個、不正解を1個登録してください。）"],
	],

	HANDLE_CROP_BRANDPITTAN: {
		width: 210,
		height: 50,
		scaleX: 1,
		scaleY: 1,
	},

	CATEGORY_GAME_VALUE: {
		POIPOI: 1,
		DONMEMO: 2,
		CARTDE_GO: 3,
		BRAND_PITTAN: 4,
		DORAKUE: 5,
		DOUSURU: 6,
	},

	PERIOD_STATUS: {
		PRIVATE: 1,
		PUBLIC: 2,
		PUBLICATION_TIME: 3,
	},

	ORDER_QUESTION_STATUS: {
		RANDOM: 1,
		SPECIFIED_ORDER: 2,
	},

	DISPLAY_TIME_STATUS: {
		NON_EXPRESSION: 1,
		COUNT_UP: 2,
		COUNT_DOWN: 3,
	},

	PAYMENT_METHOD: {
		MONTHLY: 0,
		ANNUAL: 1,
		NO_CHARGE: 2,
	},

	OPTIONNAL_PACK: [
		{
			type: 1,
			data_capacity: "10GB",
			monthly_data_transfer_volume: "30GB",
			price: "オプションなし",
			amount: null,
		},
		{
			type: 2,
			data_capacity: "50GB",
			monthly_data_transfer_volume: "150GB",
			price: "10,000円 / 月",
			amount: "10,000",
		},
		{
			type: 3,
			data_capacity: "150GB",
			monthly_data_transfer_volume: "600GB",
			price: "40,000円 / 月",
			amount: "40,000",
		},
		{
			type: 4,
			data_capacity: "2TB",
			monthly_data_transfer_volume: "6TB",
			price: "120,000円 / 月",
			amount: "120,000",
		},
	],

	OPTIONNAL_PLAN: [
		{
			type: 2,
			price: "150,000円 / 月",
		},
		{
			type: 3,
			price: "1,200,000円 / 年",
		},
		{
			type: 4,
			price: "210,000円 / 月",
		},
		{
			type: 5,
			price: "1,680,000円 / 年",
		},
	],

	DELETE_TYPE: {
		SUSPENSION: 0,
		DELETE: 1,
	},

	DESTINATION_TYPE: {
		ALL_USERS: 0,
		ALL_ORG_ADMINS: 1,
	},

	LOGIN_RATE_TYPE: {
		WEEK: 0,
		MONTH: 1,
	},

	EXPIRE_DATE_STATUS: {
		EXPIRED: 0,
		NEAR_EXPIRED: 1,
		AVAILABLE: 2,
	},

	SITUATION_TYPE: {
		DRAFT: 1,
		PUBLIC_RESERVATION: 2,
		PUBLISH_NOW: 3,
		PRIVATE: 4,
		NOW_OPEN: 5,
	},

	DELIVERY_TYPE: {
		TEXT: 0,
		EXTERNAL_LINK: 1,
		INTERNAL_LINK_GAME: 2,
		INTERNAL_LINK_COURSE: 3,
		INTERNAL_LINK_STAGE: 4,
		INTERNAL_LINK_AREA: 5,
	},

	NOTIFICATION_TYPE: {
		DRAFT: 0,
		RESERVATION: 1,
		SEND_NOW: 2,
	},

	COPY_TYPE: {
		CLONE: 0,
		CLONE_TO: 1,
	},

	FOLDER_TEMPLATE: {
		AVATAR: "avatars",
		BACKGROUND: "backgrounds",
	},

	COMPANY: {
		NPC: "npc",
	},

	RADIO_VALUE : {
		AVAILABLE_GAME: 0,
		SHARE_GAME: 1,
		MANAGEMENT_SHARE: 2,
	},

	FONT_FAMILY: [
		{ text: "Ms Gothic", value: `"Ms Gothic", sans-serif` },
		{ text: "Roboto", value: "Roboto" },
		{ text: "Arial", value: "Arial" },
	],

	FONT_STYLE: [
		{ text: "Regular", value: "" },
		{ text: "Bold", value: "bold" },
		{ text: "Italic", value: "italic" },
	],
};

const REGEX = {
	PASSWORD: /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#~.+_-]{8,}$/,
	PASSWORD_NO_NUMBER: /^(?=.*[A-Z])[a-zA-Z\d@$!%*?&#~.+_-]{8,}$/,
	PASSWORD_UPPERCASE:
		/^(?=.*[A-Z])[A-Za-z\d[\]@$!%*?&~#`^()-=+_.,/<>';:"{}\\|]{8,}$/,
	PASSWORD_NUMBER_AND_CHAR: /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
	ID: /^[a-z]{3,}$/,
	USER_ID: /^[a-z\d]+$/,
	IP_ADDRESS: /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
	HALF_WIDTH_NUMBER: /^[0-9]+$/,
	EMAIL:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

const COURSE = [
	{
		id: "1",
		name: "course1",
		quantity: 6,
		error: "ステージ数は最大6個です。",
	},
	{
		id: "2",
		name: "course2",
		quantity: 6,
		error: "ステージ数は最大6個です。",
	},
	{
		id: "3",
		name: "course3",
		quantity: 4,
		error: "ステージ数は最大4個です。",
	},
	{
		id: "4",
		name: "course4",
		quantity: 5,
		error: "ステージ数は最大5個です。",
	},
	{
		id: "5",
		name: "course5",
		quantity: 7,
		error: "ステージ数は最大7個です。",
	},
	{
		id: "6",
		name: "course6",
		quantity: 4,
		error: "ステージ数は最大4個です。",
	},
	{
		id: "7",
		name: "course7",
		quantity: 6,
		error: "ステージ数は最大6個です。",
	},
	{
		id: "8",
		name: "course8",
		quantity: 8,
		error: "ステージ数は最大8個です。",
	},
	{
		id: "9",
		name: "course9",
		quantity: 4,
		error: "ステージ数は最大4個です。",
	},
	{
		id: "10",
		name: "course10",
		quantity: 1,
		error: "ステージ数は最大1個です。",
	},
];

export { COLLECTION_FIREBASE, CONSTANTS, REGEX, COURSE };
