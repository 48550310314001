<template>
    <div>
      <v-alert
        v-model="storeMain.noAddStage"
        type="error"
        width="100%"
        icon="mdi-alert-octagon-outline"
        closable
        close-icon="mdi-close"
      >
        {{ storeMain.textError }}
      </v-alert>
  
      <v-alert
        v-model="storeMain.noAddCourse"
        type="error"
        width="100%"
        icon="mdi-alert-octagon-outline"
        closable
        close-icon="mdi-close"
        class="text-subtitle-1"
      >
        コース数は最大10個です。
      </v-alert>
  
      <p-breadcrumb-game
        :id="id"
        :status="gameStatus"
        :category="gameCategory"
        :number_of_published_url="number_of_published_url"
        :name="gameName"
        :editable="editable"
      />
  
      <v-row>
        <v-col cols="12" md="4">
          <v-card class="mb-5" v-if="editable">
            <div class="position-relative">
              <router-link
                :to="`/game-management/${id}/${
                  gameCategory == CATEGORY_GAME_VALUE.DORAKUE ? 'area' : 'course'
                }`"
                class="text-decoration-none"
              >
                <v-card-title
                  class="pa-3 justify-space-between cursor-pointer head--item"
                  v-bind:class="{ 'head--active': lengthPath == 4 && show }"
                  @click="show = true"
                >
                  <v-row align="center" justify="space-between" class="pa-3">
                    <div
                      :style="{
                        color: !checkPath() ? 'rgba(0, 0, 0, 0.6)' : '#dc2828',
                      }"
                      class="wrapper__titleSideBar"
                    >
                      <v-icon
                        :style="{
                          color: !checkPath() ? 'rgba(0, 0, 0, 0.6)' : '#dc2828',
                        }"
                        location="left"
                        class="mr-2"
                      >
                        mdi-file-tree
                      </v-icon>
  
                      <template
                        v-if="gameCategory == CATEGORY_GAME_VALUE.DORAKUE"
                      >
                        {{ $t("title.area_list") }}
                      </template>
  
                      <template v-else> {{ $t("title.course_list") }}</template>
                    </div>
  
                    <v-icon
                      v-if="show"
                      class="float-right"
                      :style="{
                        color: !checkPath() ? 'rgba(0, 0, 0, 0.6)' : '#dc2828',
                      }"
                    >
                      mdi-chevron-right
                    </v-icon>
                  </v-row>
                </v-card-title>
              </router-link>
            </div>
  
            <v-divider />
  
            <v-card-text v-if="show" class="pa-0">
              <v-responsive max-height="60vh" class="overflow-y-auto">
                <!-- !!! This component is a temporary replacement for v-treeview which is not yet available !!! -->
                <p-treeview :items="itemsTree" />
  
                <!-- <v-treeview
                  hoverable
                  transition
                  :items="itemsTree"
                  :active="[activeItem]"
                  color="error"
                  expand-icon="mdi-chevron-down"
                  item-key="key"
                  :open="openItem"
                >
                  <template v-slot:label="{ item }">
                    <router-link
                      :to="item.to"
                      class="text-decoration-none d-flex justify-space-between text--primary"
                    >
                      <div class="text-truncate body-2">
                        <v-chip outlined size="small">{{ item.title }}</v-chip>
                        {{ item.name }}
                      </div>
  
                      <v-icon
                        class="float-right"
                        v-if="item.status !== PERIOD_STATUS.PUBLIC"
                        >mdi-lock
                      </v-icon>
                    </router-link>
                  </template>
                </v-treeview> -->
              </v-responsive>
            </v-card-text>
          </v-card>
  
          <!-- <v-card class="mb-5">
            <v-expansion-panels
              class="no-border"
              accordion
              multiple
              hover
            >
              <v-expansion-panel :value="false" @group:selected="({ value }) => show = !value">
                <v-expansion-panel-title hide-actions>
                  <v-row align="center">
                    <v-icon class="icon_setting mr-2" location="left">
                      mdi-cog
                    </v-icon>
  
                    <div class="text-setting">ゲーム設定</div>
                  </v-row>
                </v-expansion-panel-title>
  
                <v-expansion-panel-text>
                  <v-list-item
                    v-for="(item, i) in filteredItems"
                    :key="i"
                    :to="`/game-management/${id}/${item.path}`"
                    :title="item.title"
                    class="text-subtitle-1 font-weight-regular"
                    color="primary"
                    exact
                  />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card> -->
  
          <v-card v-if="gameCategory == CATEGORY_GAME_VALUE.DOUSURU" class="mb-5">
            <v-expansion-panels class="no-border" accordion multiple hover>
              <v-expansion-panel>
                <v-expansion-panel-title style="min-height: 50px;" hide-actions class="panel-title-width" >
                  <v-row align="center">
                    <v-icon class="icon_setting mr-2" location="left">
                      mdi-pencil
                    </v-icon>
  
                    <div class="text-setting">{{ $t("label.answer_evaluation") }}</div>
                  </v-row>
                </v-expansion-panel-title>
                <v-divider />
                <v-expansion-panel-text class="panel-width">
                  <v-list-item
                    :to="`/game-management/${id}/answer-evaluation-dousuru`"
                    class="text-subtitle-1 font-weight-regular"
                    color="primary"
                    exact
                  >
                    <v-list-item-title color="primary" class="cursor-pointer">
                      {{ $t("title.list_of_questions_answers") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
  
          <v-card class="mb-5">
            <div class="position-relative">
              <v-card-title
                :class="['title-config d-flex align-center px-4 py-2', { 'cursor-pointer': show }]"
                @click="show = false"
              >
                <v-icon class="icon_setting mr-2" location="left">mdi-cog</v-icon>
  
                <div class="text-setting">ゲーム設定</div>
              </v-card-title>
            </div>
  
            <v-divider />
  
            <v-card-text v-if="!show" class="pa-0">
              <v-list-item
                v-for="(item, i) in filteredItems"
                :key="i"
                :to="`/game-management/${id}/${item.path}`"
                :title="item.title"
                class="text-subtitle-1 font-weight-regular"
                color="primary"
              >
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
  
        <v-col cols="12" md="8">
          <slot ></slot>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import { mapState } from "pinia";
  import { CONSTANTS, auth } from "@/utils";
  import { mainStore, courseStore, gameStore } from "@/store";
  export default {
    name: "GameDetailLayout",
    setup() {
      const storeMain = mainStore();
      const storeGame = gameStore();
      const storeCourse = courseStore();
      return {
        storeMain,
        storeGame,
        storeCourse,
      };
    },
    data() {
      return {
        PERIOD_STATUS: CONSTANTS.PERIOD_STATUS,
        CATEGORY_GAME_VALUE: CONSTANTS.CATEGORY_GAME_VALUE,
        show: true,
        id: null,
        gameName: "",
        gameCategory: 1,
        gameStatus: CONSTANTS.PERIOD_STATUS.PRIVATE,
        number_of_published_url: 0,
        lengthPath: 0,
        editable: true,
        // logo: null,
        items: [
          {
            title: this.$t("title.basic_setting"),
            path: "basic-setting",
          },
          {
            title: this.$t("title.user_group_settings"),
            path: "user-group-setting",
          },
          {
            title: this.$t("title.visibility_settings_for_organizations"),
            path: "visibility-setting",
          },
          { title: this.$t("title.public_url"), path: "public-url" },
          { title: this.$t("title.delete_game"), path: "delete-game" },
        ],
        activeItem: "",
        openItem: [],
      };
    },
    computed: {
      ...mapState(gameStore, [
        "success",
        "message",
        "datas",
        "gameDetail",
        "treeviews",
      ]),
      ...mapState(courseStore, ["listCourse"]),
      companyId() {
        return auth()?.company.company_id;
      },
      itemsTree() {
        return this.treeviews || [];
      },
      filteredItems() {
        let itemList = this.editable
          ? this.items
          : this.items.filter(
              (item) =>
                item.path !== "basic-setting" && item.path !== "public-url"
            );
        // if (!this.isBussinessCompany) {
          itemList = itemList.filter((item) =>
            item.path !== "user-group-setting"
          );
        // }
        return itemList;
      },
      isBussinessCompany() {
        return auth()?.company.usage_plan === CONSTANTS.PLAN_TYPE.BUSINESS;
      },
    },
    watch: {
      "$route.meta": {
        handler: async function (meta) {
          await this.storeMain.setOverlay(true);
  
          this.show = !meta.gameSetting;
          this.lengthPath = this.$route.path.split("/").length;
          await this.getGameById();
          if (this.show) {
            await this.getTreeView();
          }
          await this.storeMain.setOverlay(false);
        },
        deep: true,
        immediate: true,
      },
      // "$route.params": {
      //   handler: async function (params) {
      //     if (params.courseId) {
      //       this.openItem = [];
      //       this.activeItem = params.courseId;
      //     }
      //     if (params.courseId && params.stageId) {
      //       this.openItem = [params.courseId];
      //       this.activeItem = `${params.courseId}-${params.stageId}`;
      //     }
      //     if (params.areaId) {
      //       this.openItem = [];
      //       this.activeItem = params.areaId;
      //     }
      //     if (params.areaId && params.courseId) {
      //       this.openItem = [params.areaId];
      //       this.activeItem = `${params.areaId}-${params.courseId}`;
      //     }
      //     if (params.areaId && params.courseId && params.stageId) {
      //       this.openItem.push(params.areaId);
      //       this.openItem.push(`${params.areaId}-${params.courseId}`);
      //       this.activeItem = `${params.areaId}-${params.courseId}-${params.stageId}`;
      //     }
      //   },
      //   deep: true,
      //   immediate: true,
      // },
      gameDetail() {
        this.id = this.gameDetail?.id;
        this.gameName = this.gameDetail?.name;
        this.gameCategory = this.gameDetail?.category_game;
        this.gameStatus = this.gameDetail?.status;
        this.number_of_published_url = this.gameDetail?.number_of_published_url;
        this.editable = this.gameDetail?.editable;
        // this.logo = this.gameDetail ? this.gameDetail?.logo_url : null;
      },
    },
    methods: {
      checkPath() {
        if (
          this.$route.path ===
            `/game-management/${this.$route.params.gameId}/area` ||
          this.$route.path ===
            `/game-management/${this.$route.params.gameId}/course`
        ) {
          return true;
        }
        return false;
      },
      async getTreeView() {
        const gameId = this.$route.params.gameId;
        await this.storeGame.gameTreeView(this.companyId, gameId, {
          type: this.gameCategory,
        });
      },
  
      async getGameById() {
        const id = this.$route.params.gameId;
        await this.storeGame.findById(this.companyId, id);
      },
  
      // setActiveItem(item) {
      //   const params = this.$route.params;
      //   if (item.length == 0) {
      //     if (params.courseId && !params.stageId) {
      //       this.openItem = params.courseId;
      //       this.activeItem = params.courseId;
      //     }
      //     if (params.courseId && params.stageId) {
      //       this.openItem = params.courseId;
      //       this.activeItem = `${params.courseId}-${params.stageId}`;
      //     }
      //   }
      // },
    },
  };
  </script>
  
  <style lang="scss">
  
  .v-list--one-line {
      padding: 0px !important;
  }
  .icon_setting {
    height: 20px;
    width: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .text-setting {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 700;
  }
  
  .head--active {
    color: #dc2828 !important;
    caret-color: #dc2828 !important;
    &::before {
      opacity: 0.12 !important;
    }
  }
  .head--item {
    &::before {
      background-color: currentColor;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  .wrapper__titleSideBar {
    font-size: 14px;
    font-weight: 700;
  }
  .title-config {
    font-size: 16px
  }
  .panel-width .v-expansion-panel-text__wrapper {
    padding: 0px;
    max-height: 48px;
  }
  .panel-width .v-expansion-panel-text__wrapper a {
    height: 48px;
  }
  .panel-title-width {
    &.v-expansion-panel-title--active > .v-expansion-panel-title__overlay {
      opacity: 0 !important;
    }
  }
  </style>
  