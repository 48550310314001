import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from '@/utils';

const routes = [
  {
    path: "/account-info",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "AccountInfo",
        component: () => import("@/views/Account/index.vue"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("title.account_info")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("title.account_info"),
            },
          ],
        },
      },
      {
        path: "payment-history",
        name: "PaymentHistory",
        component: () => import("@/views/Account/BillHistory"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("title.account_info")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("title.account_info"),
              to: { path: "/account-info" },
            },
            {
              text: "請求履歴",
            },
          ],
        },
      },
      {
        path: "edit",
        name: "AccountEdit",
        component: () => import("@/views/Account/EditAccount"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("title.account_info")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("title.account_info"),
              to: { path: "/account-info" },
            },
            {
              text: "",
            },
          ],
        },
      },
      {
        path: "edit-card",
        name: "EditCard",
        component: () => import("@/views/Account/EditCard"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("title.credit_card_number_change")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("title.account_info"),
              to: { path: "/account-info" },
            },
            {
              text: "",
            },
          ],
        },
      },
      {
        path: "upgrade",
        name: "UpgradePlan",
        component: () => import("@/views/Account/UpgradePlan"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("title.plan_change")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("title.account_info"),
              to: { path: "/account-info" },
            },
            {
              text: "",
            },
          ],
        },
      },
      {
        path: "option-change",
        name: "DataCapacity",
        component: () => import("@/views/Account/OptionChange"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `オプション変更 - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("title.account_info"),
              to: { path: "/account-info" },
            },
            {
              text: "",
            },
          ],
        },
      },
      {
        path: "data-transfer",
        name: "DataTransfer",
        component: () => import("@/views/Account/DataTransfer"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t(
            "title.data_transfer_volume_change"
          )} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("title.account_info"),
              to: { path: "/account-info" },
            },
            {
              text: "",
            },
          ],
        },
      },
    ],
  },
];

export default routes;
