import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from '@/utils';

const routes = [
  {
    path: "/announcement-management",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "AnnouncementList",
        component: () => import("@/views/Announcement/List"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.recommendation_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.dashboard"),
            },
          ],
        },
      },
      {
        path: "create",
        name: "AnnouncementCreate",
        component: () => import("@/views/Announcement/CreateAndEdit"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.recommendation_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.recommendation_management"),
              to: { name: "AnnouncementList" },
            },
            {
              text: "",
            },
          ],
        },
      },
      {
        path: "edit/:id",
        name: "AnnouncementEdit",
        component: () => import("@/views/Announcement/CreateAndEdit"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.recommendation_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb() {
            const id = this.$route.params.id;
            return [
              {
                text: i18n.global.t("nav.recommendation_management"),
                to: { name: "AnnouncementList" },
              },
              {
                text: `${i18n.global.t("label.user_id")}: ${id}`,
              },
            ];
          },
        },
      },
    ]
  },
];

export default routes;
