<template>
  <component :is="layout">
    <v-overlay
      v-model="storeMain.overlay"
      class="align-center justify-center"
    >
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <router-view />

    <v-snackbar v-model="storeMain.show" location="top">
      {{ storeMain.text }}

      <template v-slot:actions>
        <v-btn
          :color="storeMain.color"
          variant="text"
          @click="storeMain.show = false"
        >
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<script>
import { mainStore } from "@/store";

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};
const default_layout = "main-layout";
export default {
  name: "App",
  setup() {
    const storeMain = mainStore();
    return { storeMain };
  },

  computed: {
    layout() {
      return this.$route.meta.layout ?? default_layout;
    },
  },
};
</script>
