import moment from "moment";
import { mapState } from "pinia";
import { authStore } from "@/store";

export default {
  computed: {
    ...mapState(authStore, ["dataAuthMe"]),

    gbIsExpired() {
      let check = false;
      if (this.dataAuthMe?.company) {
        const { alertError, stop_using } = this.dataAuthMe.company;
        const { expired} = alertError;
        check = stop_using || expired;
      }
      return check;
    },
  },

  methods: {
    convertDateToString(date, formatString = "YYYY/MM/DD HH:mm") {
      if (!date) {
        return "";
      }
      if(typeof(date) === "string") return date;
      return moment(date?._seconds * 1000).format(formatString);
    },

    thousandComma(number, withStyle = false) {
      if (!number) {
        return 0;
      }

      return typeof number === 'number'
        ? number.toLocaleString('ja-JP', {
          style: withStyle ? 'currency' : undefined,
          currency: 'JPY',
        })
        : null;
    },
    
    checkDuplicateImageMixin(files, dataCheck, desserts) {
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (desserts ? desserts.some(e => e.images.some((i) => i.file_name === file.name)) : false) {
          const fileExtension = file.name.split('.').pop();
          const newFileName = `${this.formatDateMixin() + i}.${fileExtension}`;
          const blob = new Blob([file], { type: file.type });
          const newFile = new File([blob], newFileName, { type: file.type });
          newFiles.push(newFile);
        } else {
          if (dataCheck.some((e) => e.file_name === file.name)) {
            const fileExtension = file.name.split('.').pop();
            const newFileName = `${this.formatDateMixin() + i}.${fileExtension}`;
            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], newFileName, { type: file.type });
            newFiles.push(newFile);
          } else {
            newFiles.push(file);
          }
        }
      }
      return newFiles;
    },

    formatDateMixin (){
      const now = moment(Date.now());
      const formattedNow = now.format('DDMMYYYYHHmmss');
      return formattedNow;
    },
  },
}
