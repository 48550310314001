<template>
  <v-list-group v-for="item in items" :key="item.key" :value="item.key">
    <template v-slot:activator="{ props, isOpen }">
      <v-list-item
        v-bind="props"
        :value="item.key"
        style="cursor: auto"
        :style="{ 'background-color': isActive(item.to) ? '#ffeaea' : '' }"
      >
        <template v-slot:prepend>
          <v-icon 
            v-if="item.children?.length > 0" 
            :icon="isOpen ? 'mdi-menu-down' : 'mdi-menu-right'"
            style="cursor: pointer"
            class="icon-color-lock"
            ></v-icon>
          <!-- <v-icon
            v-if="item.children?.length > 0"
            density="comfortable"
            :icon="isOpen ? 'mdi-menu-down' : 'mdi-menu-right'"
            :color="isActive(item.to) ? '#ffeaea' : ''"
            size="small"
            flat
          /> -->
        </template>

        <template v-slot:title>
          <router-link
            :to="item.to"
            class="text-decoration-none text-black"
            style="cursor: pointer"
          >
            <div class="text-truncate text-body-2">
              <v-chip variant="outlined" size="small">
                {{ item.title }}
              </v-chip>
              {{ item.name }}
            </div>
          </router-link>
        </template>

        <template v-slot:append>
          <router-link
            :to="item.to"
            class="text-decoration-none text-black"
            style="cursor: pointer"
          >
            <v-icon
              class="float-right icon-color-lock"
              v-if="item.status !== PERIOD_STATUS.PUBLIC"
            >
              mdi-lock
            </v-icon>
          </router-link>
        </template>
      </v-list-item>
    </template>

    <template v-slot:default v-if="item.children?.length > 0">
      <div class="child-group-items">
        <ListGroup :items="item.children" />
      </div>
    </template>
  </v-list-group>
</template>

<script>
import { defineComponent } from "vue";
import { CONSTANTS } from "@/utils";

export default defineComponent({
  name: "ListGroup",

  props: {
    items: { type: Array, default: () => [] },
  },

  setup() {
    const PERIOD_STATUS = CONSTANTS.PERIOD_STATUS;
    return {
      PERIOD_STATUS,
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-list-item__prepend) {
  width: 28px;
}
/* :deep(.v-list-item--active .v-list-item__append > .v-icon) {
  opacity: 0.6;
} */
.child-group-items {
  .v-list-item {
    padding-left: 40px !important;
  }
  .v-list-group__items {
    .child-group-items {
      .v-list-item {
        padding-left: 60px !important;
      }
    }
  }
}

</style>
