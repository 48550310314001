<template>
  <div>
    <p-alert type="warning" class="w-100" v-if="storage >= 80 && storage <= 99.99">
      <p class="mb-0">
        データ容量が80%を超えました。100%を超えると管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合は不要なデータを削除するか、プランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>

    <p-alert type="error" class="w-100" v-if="storage >= 100">
      <p class="mb-0">
        データ容量が100%を超えました。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合は不要なデータを削除するか、プランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>

    <p-alert type="warning" class="w-100" v-if="bandwidth >= 80 && bandwidth <= 99.99">
      <p class="mb-0">
        月内データ転送量が80%を超えました。100%を超えると管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合はプランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>

    <p-alert type="error" class="w-100" v-if="bandwidth >= 100">
      <p class="mb-0">
        月内データ転送量が100%を超えました。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合はプランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>
    <!-- read count -->
    <p-alert type="warning" class="w-100" v-if="readCount >= 80 && readCount <= 99.99">
      <p class="mb-0">
        読み込み回数が80%を超えました。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合は不要なデータを削除するか、プランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>

    <p-alert type="error" class="w-100" v-if="readCount >= 100">
      <p class="mb-0">
        読み込み回数が100%を超えました。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合は不要なデータを削除するか、プランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>
    <!-- write count -->
    <p-alert type="warning" class="w-100" v-if="writeCount >= 80 && writeCount <= 99.99">
      <p class="mb-0">
        書き込み回数が80%を超えました。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合は不要なデータを削除するか、プランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>

    <p-alert type="error" class="w-100" v-if="writeCount >= 100">
      <p class="mb-0">
        書き込み回数が100%を超えました。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合は不要なデータを削除するか、プランのアップグレードやオプションを変更して空き容量を増やしてください。
      </p>
    </p-alert>
    <p-alert type="error" class="w-100" style="white-space: break-spaces;" v-if="isErrorCard">
      <p class="mb-0">
        決済が完了できませんでした。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合はクレジットカード情報を更新してください。
      </p>
    </p-alert>

    <p-alert type="warning" class="w-100" v-if="!hasRed && card_expire_soon">
      <p class="mb-0">
        ご利用中のクレジットカードがまもなく期限切れになります。
      </p>
      <p class="mb-0">
        継続利用される場合はクレジットカード情報を更新してください。
      </p>
    </p-alert>

    <!-- <p-alert
      type="error"
      class="w-100"
      v-if="card_null && PLAN_TYPE.FREE_TRIAL != plan"
    >
      <p class="mb-0">
        決済が完了できませんでした。管理画面の利用が制限されます。
      </p>
      <p class="mb-0">
        継続利用される場合はクレジットカード情報を更新してください。
      </p>
    </p-alert> -->

    <p-alert type="error" class="w-100" v-if="card_null">
      <p class="mb-0">決済が完了できませんでした。管理画面の利用が制限されます。</p>
      <p class="mb-0">
        継続利用される場合はクレジットカード情報を更新してください。
      </p>
    </p-alert>

    <p-alert type="error" class="w-100" v-if="card_expired">
      <p class="mb-0">決済が完了できませんでした。管理画面の利用が制限されます。</p>
      <p class="mb-0">
        継続利用される場合はクレジットカード情報を更新してください。
      </p>
    </p-alert>

    <p-alert type="warning" class="w-100" v-if="stop_using ? false : expire_soon">
      <p class="mb-0">まもなく有効期限が終了いたします。</p>
      <p class="mb-0">継続利用される場合はお支払い情報を更新してください。</p>
    </p-alert>
   
    <p-alert type="error" class="w-100" v-if="stop_using ? false : expired">
      <p class="mb-0">有効期限が終了しました。</p>
      <p class="mb-0">
        アカウントは有効期限から90日後に削除されます。継続利用される場合はお支払い情報を更新してください。
      </p>
    </p-alert>

    <p-alert type="error" class="w-100" v-if="stop_using">
      <p class="mb-0">利用を停止しています。</p>
      <p class="mb-0">
        利用を再開される場合は、プランの「利用再開」よりお申し込みください。
      </p>
    </p-alert>
  </div>
</template>

<script>
import { authStore } from "@/store";
import { CONSTANTS } from "@/utils";
import { mapState } from "pinia";

export default {
  name: "PGAlertMessage",
  setup() {
    const storeAuth = authStore();

    return { storeAuth };
  },
  data: () => ({     
    PLAN_TYPE: CONSTANTS.PLAN_TYPE,
    expire_soon: false,
    expired: false,
    card_expire_soon: false,
    card_null: false,
    storage: 0,
    stop_using: false,
    bandwidth: 0,
    card_expired: false,
    plan: 1,
    hasRed: false,
    isErrorCard: false,
    readCount: 0,
    writeCount: 0,
    deleteCount: 0,
  }),
  computed: {
    ...mapState(authStore, ["dataAuthMe"]),

    company() {
      return this.dataAuthMe?.company;
    },
  },
  watch: {
    dataAuthMe(value) {
      if(this.company?.card !== null){
         this.isErrorCard= !this.company?.card?.to_enable;
        }
      const alertError = value?.company?.alertError;
      const company = value?.company;
      this.expire_soon = alertError?.expire_soon;
      this.expired = alertError?.expired;
      this.card_null = alertError?.card_null;
      this.card_expire_soon = alertError?.card_expire_soon;
      this.stop_using = company?.stop_using;
      this.storage = company?.used_capacity.percent;
      this.card_expired = alertError?.card_expired;
      this.payment_error = alertError?.payment_error;
      this.plan = company.usage_plan;
      this.bandwidth = company.used_monthly_data_transfer_volume.percent;
      this.readCount = company.used_read.percent;
      this.writeCount = company.used_write.percent;
      this.deleteCount = company.used_delete.percent;
      this.hasRed =
        this.storage >= 100 ||
        this.bandwidth >= 100 ||
        this.card_null ||
        this.card_expired ||
        this.expired ||
        this.stop_using;
        this.readCount >= 100 ||
        this.writeCount >= 100 ||
        this.deleteCount >= 100
      // const expiration_date = moment(company?.expiration_date, "YYYY/MM/DD").diff(
      //   moment(),
      //   "days"
      // );
      // this.expire_soon = expiration_date < 5;
      // this.expired = expiration_date < 0;
      // const card = company?.card;
      // this.card_null = this.expired && !card;
      // if (card) {
      //   const expiration_card = moment(`${card.year}/${card.moon}`, "YYYY/MM")
      //     .endOf("months")
      //     .diff(moment(), "days");
      //   this.card_expire_soon = expiration_card < 5;
      // }
    },
  },
};
</script>

<style></style>
