<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>
    <dashboard-core-footer />
  </v-app>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: "BaseLayout",
  components: {
    DashboardCoreFooter: defineAsyncComponent(() => import("@/components/PGFooter")),
  },
};
</script>

<style lang="scss">
.pg-singin {
  $px: $spacer * 4;
  width: 100%;
  max-width: 600px + ($px * 2);
  margin: $spacer * 15 auto $spacer * 20 !important;
  padding: 0 $px !important;
  .pg-imge {
    max-width: 388px;
    width: 100%;
    height: auto;
    margin: 0 auto #{$spacer * 12};
  }

  .pg-custom-checkbox {
    display: flex;
    justify-content: center;
    &__information {
      margin-top: 0;
    }
  }
}
.pg-error {
  margin: #{$spacer * 12} 0;
}
.my-12,
.mt-12 {
  margin-top: $spacer * 12;
}
.my-12,
.mb-12 {
  margin-bottom: $spacer * 12;
}
</style>
