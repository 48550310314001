<template>
  <div class="wrapper-alert">
    <div class="wrapper-alert_title">
      <v-icon size="23" class="custom_icon" color="white">
        mdi-alert-outline
      </v-icon>
      <span class="wrapper-alert_title_text">プランについての注意</span>
    </div>
    <div class="wrapper-alert_content">
      <div>
        <p class="text_content">
          <span class="mr-2">1.</span>プランごとに決済方法が異なります。
        </p>
        <div class="ml-5">
          <p class="text_content">a. 支払い無し：無料トライアル、無料シェア</p>
          <p class="text_content">
            b. クレジットカード支払いのみ：スタンダード、プレミアム
          </p>
          <p class="text_content">c. 銀行振込のみ：ビジネス</p>
        </div>
        <p class="text_content"><span class="mr-2">2.</span>プラン変更の制限</p>
        <div class="ml-5">
          <p class="text_content">
            a. 決済方法が異なる下位プランには変更できません。
          </p>
          <p class="ml-5 text_content">
            &nbsp;i. ビジネスは他のプランに変更できません。
          </p>
          <p class="ml-5 text_content">
            ii. 他のプランから無料トライアルに変更できません。
          </p>
          <p class="ml-5 text_content">
            iii. 他のプランから無料シェアに変更できません。
          </p>
        </div>
        <p class="text_content">
          <span class="mr-2">3.</span>プレミアム、スタンダードへのプラン変更
        </p>
        <div class="ml-7">
          <p class="text_content">
            <span style="font-size: 6px" class="mr-2">&#9679;</span
            >プラン：保存後に即時反映
          </p>
          <p class="text_content">
            <span style="font-size: 6px" class="mr-2">&#9679;</span
            >請求：翌月1日
          </p>
          <p class="ml-6 text_content">
            <span style="font-size: 6px" class="mr-2">&#9679;</span
            >企業の管理画面でクレジットカード登録がない場合は決済エラーになります。
          </p>
          <p class="ml-12 text_content">
            <span style="font-size: 6px" class="mr-2">&#9679;</span
            >決済エラーの状態ではゲームの利用や編集はできません。
          </p>
          <p class="ml-12 text_content">
            <span style="font-size: 6px" class="mr-2">&#9679;</span
            >企業管理画面でクレジットカード登録および決済完了後に利用を再開できます。
          </p>
        </div>
        <p class="text_content">
          <span class="mr-2">4.</span>ビジネスプランへの変更
        </p>
        <div class="ml-5">
          <p class="text_content ml-2">
            <span style="font-size: 6px" class="mr-2">&#9679;</span>
            容量、銀行振込先など事前確認が必要です。
          </p>
        </div>
        <p class="text_content">
          <span class="mr-2">5.</span
          >変更履歴は保存されません。アラート等も送信されません。
        </p>
        <div class="ml-1">
          <p style="color: #dc2828" class="ml-6 text_content">
            <span style="font-size: 6px" class="mr-2">&#9679;</span
            >当画面でプランを変更した場合は、翌月1日時点でのプランで請求されます。
          </p>
          <p style="color: #dc2828" class="ml-12 text_content">
            <span style="font-size: 6px" class="mr-2">&#9679;</span
            >特殊対応などで一時的にプランを変更する場合など、後日内容を修正する予定がある場合は、他のツールで管理してください。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "demoAlertNote",
};
</script>

<style lang="scss" scoped>
.custom_icon {
  margin: 0 15px 0 15px;
}
.text_content {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 2px;
}
.wrapper-alert {
  border-radius: 10px;
  border: #ffa500 5px solid;
  &_title {
    min-height: 56px;
    background: orange;
    display: flex;
    justify-content: start;
    align-items: center;
    &_text {
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
    }
  }
  &_content {
    padding: 20px;
  }
}
</style>
