import { nextTick } from 'vue'
import {createRouter, createWebHistory} from "vue-router";
import { authRouter, restrictRoute } from "@/utils";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition ?? { x: 0, y: 0 };
  },
})


const DEFAULT_TITLE = "PIN to GAMES - Admin";
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

router.beforeEach(async (to, from, next) => {
  const { isAuth, initialSetting } = authRouter();
  if (to.path === "/login" && isAuth) {
    next("/");
    return;
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    initialSetting &&
    isAuth
  ) {
    next("/initial-setting");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuth) {
    next("/login");
    return;
  }

  if (restrictRoute(to)) {
    next("/page-not-found");
    return;
  }

  next();
});

export default router;
