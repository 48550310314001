import i18n from "@/i18n";
//import { useI18n } from 'vue-i18n'
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from "@/utils";

const routes = [
	{
		path: "/",
		component: Dashboard, // default layout for all children
		children: [
			{
				path: "",
				name: "Dashboard",
				component: () => import("@/views/Dashboard"),
				meta: {
					title: `${i18n.global.t("nav.dashboard")} - PIN to GAMES`,
					requiresAuth: true,
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					breadCrumb: [
						{
							text: i18n.global.t("nav.dashboard"),
						},
					],
				},
			},

			{
				path: "login-rate",
				name: "LoginRate",
				component: () => import("@/views/LoginRate/index.vue"),
				meta: {
					roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
					title: `${i18n.global.t("nav.login_rate")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.login_rate"),
						},
					],
				},
			},

			{
				path: "npc-login-rate",
				name: "NPCLoginRate",
				component: () => import("@/views/NPC/NPCLoginRate/index.vue"),
				meta: {
					roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
					title: `${i18n.global.t("nav.login_rate")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.login_rate"),
						},
					],
				},
			},

			{
				path: "score-history",
				name: "ScoreHistory",
				component: () => import("@/views/ScoreHistory/index.vue"),
				meta: {
					roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
					title: `${i18n.global.t("nav.score_history")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.score_history"),
						},
					],
				},
			},
		],
	},
];

export default routes;
