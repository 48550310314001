<template>
  <v-alert
    :type="type"
    :icon="icons[type]"
    :closable="closable"
    close-icon="mdi-close"
    style="white-space: pre-line"
    :width="width">
      <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: "PGAlert",
  props: {
    type: String,
    message: String,
    closable: Boolean,
    alert: Boolean,
    width : String ,
  },
  data: () => ({
    icons: {
      success: "mdi-checkbox-marked-circle",
      error: "mdi-alert-octagon-outline",
      warning: "mdi-alert-outline",
      info: "mdi-information-outline",
    },
  }),
};
</script>