<template>
  <PGHeader class="p-card-header" v-bind="$attrs" type="card">
    <template v-for="(value, name) in $slots" v-slot:[name]>
      <slot :name="name"> </slot>
    </template>
  </PGHeader>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: "PGCardHeader",
  components: {
    PGHeader: defineAsyncComponent(() => import("@/components/PGHeader")),
  },
};
</script>
