import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from "@/utils";
function companyId() {
	const store = localStorage.getItem(CONSTANTS.AUTH_STORAGE);
	return store ? JSON.parse(store).company.company_id : "";
}
const routes = [
	{
		path: "/game-management",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "GameManagement",
				component: () => import("@/views/GameManagement/List"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "order",
				name: "order",
				component: () => import("@/views/GameManagement/Order"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "logo-management",
				name: "LogoManagement",
				component: () => import("@/views/GameManagement/Logo"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "url-management",
				name: "UrlManagement",
				component: () => import("@/views/GameManagement/UrlManagement"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},

			{
				path: "email-management",
				name: "EmailManagement",
				component: () => import("@/views/GameManagement/EmailRegistration"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},

			{
				path: ":gameId/basic-setting",
				name: "BasicSetting",
				component: () => import("@/views/GameManagement/BasicSetting"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},

			{
				path: ":gameId/user-group-setting",
				name: "UserGroupSetting",
				component: () => import("@/views/GameManagement/UserGroupSetting"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":gameId/visibility-setting",
				name: "VisibilitySetting",
				component: () => import("@/views/GameManagement/VisibilitySetting"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":gameId/delete-game",
				name: "DeleteGame",
				component: () => import("@/views/GameManagement/DeleteGame"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},
	{
		path: "/game-management/share-game",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "ShareGame",
				component: () => import("@/views/GameManagement/ShareGame"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "create",
				name: "CreateShareGame",
				component: () => import("@/views/GameManagement/ShareGame/Create"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "create/:id",
				name: "CreateShareGameId",
				component: () => import("@/views/GameManagement/ShareGame/Create"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "detail/:id",
				name: "ShareGameDetail",
				component: () => import("@/views/GameManagement/ShareGame/Detail"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "management/edit",
				name: "ManagementGameShareEdit",
				component: () => import("@/views/GameManagement/ShareGame/ManagementEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
		],
	},
	{
		path: "/game-management/avatar-management",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "AvatarManagement",
				component: () => import("@/views/GameManagement/Avatar"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},

			{
				path: "preview-mode",
				name: "PreviewMode",
				component: () => import("@/views/GameManagement/Avatar/PreviewMode"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},

			{
				path: "create",
				name: "CreateAvatar",
				component: () => import("@/views/GameManagement/Avatar/CreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},

			{
				path: "edit/:id",
				name: "EditAvatar",
				component: () => import("@/views/GameManagement/Avatar/CreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
			{
				path: "setting",
				name: "SettingAvata",
				component: () => import("@/views/GameManagement/Avatar/SettingAvata"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.game_management"),
						},
					],
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/public-url",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "PublicUrl",
				component: () => import("@/views/GameManagement/PublicUrl"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "create",
				name: "CreatePublicUrl",
				component: () =>
					import("@/views/GameManagement/PublicUrl/CreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "edit/:id",
				name: "EditPublicUrl",
				component: () =>
					import("@/views/GameManagement/PublicUrl/CreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/course",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "GameCourse",
				component: () => import("@/views/GameManagement/Course/List"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":id/edit",
				name: "GameCourseEdit",
				component: () => import("@/views/GameManagement/Course/Edit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":courseId/detail",
				name: "GameCourseDetail",
				component: () => import("@/views/GameManagement/Course/Detail"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "order",
				name: "GameCourseOrder",
				component: () => import("@/views/GameManagement/Course/Order"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/course/:courseId/stage",
		component: Dashboard,
		children: [
			{
				path: "order",
				name: "GameStageOrder",
				component: () => import("@/views/GameManagement/Course/Stage/Order"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "create",
				name: "GameStageCreate",
				component: () =>
					import("@/views/GameManagement/Course/Stage/CreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":id/edit",
				name: "GameStageEdit",
				component: () =>
					import("@/views/GameManagement/Course/Stage/CreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":stageId/detail",
				name: "GameStageDetail",
				component: () => import("@/views/GameManagement/Course/Stage/Detail"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/course/:courseId/stage/:stageId/problem",
		component: Dashboard,
		children: [
			{
				path: "order",
				name: "GameStageProblemOrder",
				component: () =>
					import("@/views/GameManagement/Course/Stage/ProblemOrder"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "create",
				name: "ProblemCreate",
				component: () =>
					import("@/views/GameManagement/Course/Stage/ProblemCreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":id/edit",
				name: "ProblemEdit",
				component: () =>
					import("@/views/GameManagement/Course/Stage/ProblemCreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/area",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "GameAreaList",
				component: () => import("@/views/GameManagement/Area/List"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":areaId/detail",
				name: "GameAreaDetail",
				component: () => import("@/views/GameManagement/Area/Detail"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":areaId/edit",
				name: "GameAreaEdit",
				component: () => import("@/views/GameManagement/Area/Edit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "order",
				name: "GameAreaOrder",
				component: () => import("@/views/GameManagement/Area/Order"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/area/:areaId/course",
		component: Dashboard,
		children: [
			{
				path: ":courseId/detail",
				name: "GameAreaCourseDetail",
				component: () => import("@/views/GameManagement/Area/Course/Detail"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":courseId/edit",
				name: "GameAreaCourseEdit",
				component: () => import("@/views/GameManagement/Area/Course/Edit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "order",
				name: "GameAreaCourseOrder",
				component: () => import("@/views/GameManagement/Area/Course/Order"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/area/:areaId/course/:courseId/stage",
		component: Dashboard,
		children: [
			{
				path: ":stageId/detail",
				name: "GameAreaCourseStageDetail",
				component: () =>
					import("@/views/GameManagement/Area/Course/Stage/Detail"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: ":stageId/edit",
				name: "GameAreaCourseStageEdit",
				component: () =>
					import("@/views/GameManagement/Area/Course/Stage/Edit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "order",
				name: "GameAreaCourseStageOrder",
				component: () =>
					import("@/views/GameManagement/Area/Course/Stage/Order"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/area/:areaId/course/:courseId/stage/:stageId/problem",
		component: Dashboard,
		children: [
			{
				path: ":problemId/edit",
				name: "GameAreaCourseStageProblemEdit",
				component: () =>
					import(
						"@/views/GameManagement/Area/Course/Stage/Problem/CreateAndEdit"
					),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "create",
				name: "GameAreaCourseStageProblemCreate",
				component: () =>
					import(
						"@/views/GameManagement/Area/Course/Stage/Problem/CreateAndEdit"
					),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "generate",
				name: "GameAreaCourseStageProblemGenerate",
				component: () =>
					import(
						"@/views/GameManagement/Area/Course/Stage/Problem/CreateAndEdit"
					),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "order",
				name: "GameAreaCourseStageProblemOrder",
				component: () =>
					import("@/views/GameManagement/Area/Course/Stage/Problem/Order"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},

	{
		path: "/game-management/:gameId/answer-evaluation-dousuru",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "AnswerEvaluationDousuru",
				component: () => import("@/views/GameManagement/AnswerEvaluationDousuru"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					gameSetting: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			}
		],
	},
	{
		path: "/game-management/:gameId/getAnswerId/:courseId/:stageId/:questionId",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "AnswerEvaluationDousuruList",
				component: () => import("@/views/GameManagement/AnswerEvaluationDousuru/List"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.game_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = `${companyId()}-${this.$route.params.gameId}`;
						return [
							{
								text: i18n.global.t("nav.game_management"),
								to: { name: "GameManagement" },
							},
							{
								text: `${i18n.global.t("label.game_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	}

];

export default routes;
