import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from "@/utils";

const routes = [
  {
    path: "/npc-share-game",
    component: Dashboard,
    children: [
      {
        path: "",
        name: "NPCShareGame",
        component: () => import("@/views/NPC/NPCShareGame/List"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.share_game")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.share_game"),
            },
          ],
        },
      },
      {
        path: "edit",
        name: "DetailAndEditShareGame",
        component: () => import("@/views/NPC/NPCShareGame/Edit"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.share_game")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb() {
            return [
              {
                text: i18n.global.t("nav.share_game"),
                to: { name: "NPCShareGame" },
              },
              {
                text: i18n.global.t("text.share_game_edit"),
              },
            ];
          },
        },
      },
    ],
  },
];

export default routes;
