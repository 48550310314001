import { defineStore } from "pinia";
import { URLS, API, handleGetMessageError } from "../../utils";
export const gameShareStore = defineStore("gameShare", {
  state: () => ({
    success: true,
    message: "",
    listCompanyShare: [],
    listGameCanShare: [],
    listGameShare: [],
    listAvailableGames: [],
    listManageShare: [],
    gameShareDetail: {},
    radioShareGame: 0,
    gameShareURL: null,
    checkGameShareData: false,
  }),

  actions: {
    setRadioShareGame(value) {
      this.radioShareGame = value;
    },
    async getListCompanyShareAvailable(companyId, gameId) {
      await API.requestServer
        .get(`${companyId}/${URLS.LIST_COMPANY_SHARE_AVAILABLE}/${gameId}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listCompanyShare = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async getListCompanyCanShare(companyId) {
      await API.requestServer
        .get(`${companyId}/${URLS.LIST_COMPANY_CAN_SHARE}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listCompanyShare = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    // async getListCompanyCanShareAvailable(companyId, gameId) {
    //   await API.requestServer
    //     .get(`${companyId}/${URLS.LIST_COMPANY_SHARE_AVAILABLE}/${gameId}`)
    //     .then((response) => {
    //       const { data } = response;
    //       this.success = true;
    //       this.listCompanyShare = data.data;
    //     })
    //     .catch((error) => {
    //       this.success = false;
    //       this.message = handleGetMessageError(error);
    //     });
    // },
    async getListGameShareAvailable(companyId) {
      await API.requestServer
        .get(`${companyId}/${URLS.LIST_GAME_SHARE_AVAILABLE}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listGameCanShare = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async createNewShareGame(companyId, request) {
      await API.requestServer
        .post(`${companyId}/${URLS.CREATE_NEW_SHARE_GAME}`, request)
        .then(() => {
          this.success = true;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        })
    },
    async getListGameShareToOtherCompany(companyId) {
      await API.requestServer
        .get(`${companyId}/${URLS.LIST_GAME_SHARE_TO_OTHER_COMPANY}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listGameShare = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        })
    },
    async getListGameShareFromOtherCompany(companyId) {
      await API.requestServer
      .get(`${companyId}/${URLS.LIST_GAME_SHARE_FROM_OTHER_COMPANY}`)
      .then((response) => {
        const { data } = response;
        this.success = true;
        this.listAvailableGames = data.data;
      })
      .catch((error) => {
        this.success = false;
        this.message = handleGetMessageError(error);
      })
    },
    async cloneGame(companyId, queueId, request) {
      await API.requestServer
      .post(`${companyId}/${URLS.GAME}/${URLS.CLONE}/${queueId}`, request)
      .then(() => {
        this.success = true;
      })
      .catch((error) => {
        this.success = false;
        this.message = handleGetMessageError(error);
      })
    },
    async getDetailGameShare(companyId, gameShareId) {
      await API.requestServer
      .get(`${companyId}/${URLS.GET_SHARE_GAME_DETAILS}/${gameShareId}`)
      .then((response) => {
        const { data } = response;
        this.success = true;
        this.gameShareDetail = data.data;
      })
      .catch((error) => {
        this.success = false;
        this.message = handleGetMessageError(error);
      })
    },
    async editGameShare(companyId, game_share_id, request) {
      await API.requestServer
      .patch(`${companyId}/${URLS.EDIT_GAME_SHARE}/${game_share_id}`, request)
      .then(() => {
        this.success = true;
      })
      .catch((error) => {
        this.success = false;
        this.message = handleGetMessageError(error);
      })
    },
    async deleteGameShare(companyId, game_share_id, request) {
      await API.requestServer
      .post(`${companyId}/${URLS.DELETE_GAME_SHARE}/${game_share_id}`, request)
      .then(() => {
        this.success = true;
      })
      .catch((error) => {
        this.success = false;
        this.message = handleGetMessageError(error);
      })
    },
    async getListManageGameShare(companyId) {
      await API.requestServer
        .get(`${companyId}/${URLS.LIST_MANAGE_GAME_SHARE}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listManageShare = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        })
    },
    async createGameShareURL(companyId) {
      await API.requestServer
        .post(`${companyId}/${URLS.CREATE_GAME_SHARE_URL}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.gameShareURL = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        })
    },
    async getGameShareURL(companyId) {
      console.log("getGameShareURL");
      await API.requestServer
        .get(`${companyId}/${URLS.GET_GAME_SHARE_URL}`)
        .then((response) => {
          const { data } = response;
          console.log(data);
          this.success = true;
          this.gameShareURL = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        })
    },
    async updateShareGameCompany(request) {
      await API.requestServer
        .patch(`${URLS.UPDATE_SHARE_GAME_COMPANY}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.datas = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async checkGameShareURL(request) {
      await API.requestServer
        .post(`auth/${URLS.CHECK_GAME_SHARE_URL}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.checkGameShareData = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        })
    },
  },
});
