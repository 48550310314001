<template>
    <v-tooltip :location="position" :disabled="disabled">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" :color="color" :class="classIcon" :disabled="disabled" :style="style" @click="handleClick">
        {{ icon }}
      </v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
  </template>
  
  <script>
  export default {
    name: "PGTooltip",
    props: {
        icon: String,
        color:  String,
        tooltipText: String,
        disabled: {
          type: Boolean,
          default: false
        },
        classIcon: {
          type: String,
          default: 'mx-2'
        },
        position: {
            type: String,
            default: 'bottom'
        },
        style: Object,
    },
    methods: {
        handleClick() {
            this.$emit('click');
        }
    }
  };
  </script>
 <style lang="scss" scoped>
  :deep(.v-overlay__content) {
    background-color: #717171 !important;
  }
</style>
  