<template>
  <v-dialog v-model="dialogFlag" scrollable max-width="270px">
    <v-card>
      <v-card-title class="title_text"
        >画像を編集しますか？</v-card-title
      >
      <v-card-actions>
        <v-btn
          class="action_text"
          color="#000000"
          variant="text"
          @click="handleUpload(true)"
        >
          {{ $t("text.no_edit") }}
        </v-btn>
        <v-btn
          class="action_text"
          variant="flat"
          color="success"
          @click="handleUpload(false)"
        >
          {{ $t("text.edit_image") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "dialogConfirmUpload",
  props: {
    showDialogFlag: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogFlag: {
      get() {
        return this.showDialogFlag;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleUpload(isCheckUpload) {
      this.dialogFlag = false;
      this.$emit("handleCheckUpload", isCheckUpload);
    },
  },
};
</script>

<style lang="scss" scoped>
.title_text {
  justify-content: center ; 
  padding: 0;
  margin: 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.action_text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.0089em;
  text-align: left;
}
.wrapper_option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px 0;
}
</style>
>
