<template>
  <span
    class="p-icon-btn"
  >
    <v-tooltip
      :disabled="disabled"
      :location="'bottom'"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="{ ...props, ...$attrs }"
          @click.stop=""
          icon
          :disabled="disabled"
          variant="plain"
        >
          <v-icon style="font-size: 24px;">{{ tooltipIcon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: "PGIconBtn",
  props: {
    tooltipText: { type: String, default: null },
    tooltipIcon: { type: String, default: null },
    top: { type: Boolean, default: null },
    left: { type: Boolean, default: null },
    right: { type: Boolean, default: null },
    bottom: { type: Boolean, default: null },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    hasInfoSlot () {
      return !!this.$slots['info']
    },
    position () {
      if (this.bottom !== null) {
        return 'bottom';
      }
      if (this.top !== null) {
        return 'top';
      }
      if (this.left !== null) {
        return 'left';
      }
      if (this.right !== null) {
        return 'right';
      }
      return 'bottom';
    }
  }
};
</script>

<style lang="scss" scoped>
.p-icon-btn {
  display: inline-block;
  width: fit-content;
}
:deep(.v-overlay__content) {
  background-color: #717171 !important;
}
</style>
