import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from "@/utils";

const routes = [
	{
		path: "/user-management",
		component: Dashboard,
		children: [
			{
				path: "",
				name: "UserManagement",
				component: () => import("@/views/User/List/index.vue"),
				meta: {
					roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
					title: `${i18n.global.t("nav.user_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.user_management"),
						},
					],
				},
			},
			{
				path: "create",
				name: "CreateUser",
				component: () => import("@/views/User/CreateAndEdit"),
				meta: {
					roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
					title: `${i18n.global.t("nav.user_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.user_management"),
							to: { name: "UserManagement" },
						},
						{
							text: "",
						},
					],
				},
			},
			{
				path: "edit/:userId",
				name: "EditUser",
				component: () => import("@/views/User/CreateAndEdit"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.user_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const userId = this.$route.params.userId;
						return [
							{
								text: i18n.global.t("nav.user_management"),
								to: { name: "UserManagement" },
							},
							{
								text: `${i18n.global.t("label.user_id")}: ${userId}`,
							},
						];
					},
				},
			},
			{
				path: "create-user-group",
				name: "CreateUserGroup",
				component: () => import("@/views/User/CreateAndEditUserGroup"),
				meta: {
					roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
					title: `${i18n.global.t("nav.user_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb: [
						{
							text: i18n.global.t("nav.user_management"),
							to: { name: "UserManagement" },
						},
						{
							text: "",
						},
					],
				},
			},
			{
				path: "edit-user-group/:id",
				name: "EditUserGroup",
				component: () => import("@/views/User/CreateAndEditUserGroup"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.user_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = this.$route.params.id;
						return [
							{
								text: i18n.global.t("nav.user_management"),
								to: { name: "UserManagement" },
							},
							{
								text: `${i18n.global.t("label.user_id")}: ${id}`,
							},
						];
					},
				},
			},
			{
				path: "detail-user-group/:id",
				name: "DetailUserGroup",
				component: () => import("@/views/User/DetailUserGroup"),
				meta: {
					roles: [
						CONSTANTS.TYPE_ROLE.OWNER,
						CONSTANTS.TYPE_ROLE.ADMIN,
						CONSTANTS.TYPE_ROLE.GAME_ADMIN,
					],
					title: `${i18n.global.t("nav.user_management")} - PIN to GAMES`,
					requiresAuth: true,
					breadCrumb() {
						const id = this.$route.params.id;
						return [
							{
								text: i18n.global.t("nav.user_management"),
								to: { name: "UserManagement" },
							},
							{
								text: `${i18n.global.t("label.user_id")}: ${id}`,
							},
						];
					},
				},
			},
		],
	},
];

export default routes;
