<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <v-card>
      <v-card-title class="mb-2">{{ title }}</v-card-title>
      <v-card-text style="white-space: pre-line">
        {{ message }}
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn v-if="btnCancel == 'show'" variant="text" @click="cancel">
          {{ btnCancelText }}
        </v-btn>

        <v-btn
          :color="btnOkColor"
          v-if="btnOk == 'show'"
          :loading="storeMain.loadingBtn"
          :disabled="storeMain.loadingBtn"
          @click="agree"
          :title="btnOkIsText"
          variant="elevated"
        >
          {{ btnOkText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mainStore } from "@/store";
import { mapState } from "pinia";
export default {
  name: "PGDialogConfirm",

  setup() {
    const storeMain = mainStore();
    return {
      storeMain,
    };
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    maxWidth: 400,
    title: "",
    message: "",
    btnCancelText: "",
    btnOkText: "",
    btnCancel: "show",
    btnOk: "show",
    btnOkColor: "error",
    btnOkIsText: false,
  }),

  computed: {
    ...mapState(mainStore, ["loadingBtn"]),
  },

  methods: {
    open(data) {
      this.dialog = true;
      this.title = data.title || null;
      this.message = data.message || null;
      this.btnOk = data.btnOk || "show";
      this.btnOkText = data.btnOkText || "OK";
      this.btnCancel = data.btnCancel || "show";
      this.btnOkColor = data.btnOkColor ?? "error";
      this.maxWidth = data.maxWidth || this.maxWidth;
      (this.btnOkIsText = data.btnOkIsText || false),
        (this.btnCancelText = data.btnCancelText || this.$t("text.cancel"));

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>