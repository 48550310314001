<template>
  <v-data-table
    loading-text="読み込み中です..."
    :headers="localHeaders"
    :items="items"
    :search="search"
    @onRowClick="row"
    :custom-filter="customSearch"
    @current-items="$emit('current-items', $event)"
    :loading="loading"
    :row-props="itemClass"
    itemsPerPageText="行数:"
    :showCurrentPage=true
    :showFirstLastPage=true
    :itemsPerPageOptions="[5,10,15,-1]"
    :headerProps="rowPointer ? { className: 'row-pointer' }  : underfined"
    calculate-widths
    hover
    class="text-body-2"
  >
    <template
      v-for="slot in Object.keys($slots)"
      v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "PGTable",
  props: {
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    search: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    keysSearch: { type: Array, default: () => [] },
    itemClass: { type: Function, default: () => ({}) },
    rowPointer: { type: Boolean, default: false },
  },

  watch: {
    headers: {
      handler(cols) {
        if (cols.length) {
          const mapHeaders = cols.map((col) => {
            const title = col.text;
            return {
              ...col,
              title,
            };
          });
          this.localHeaders = mapHeaders;
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      localHeaders: [],
    };
  },

  methods: {
    customSearch(value, search, item) {
      if (!search) return true;
      
      const searchColumns = this.headers.filter(header => header.value !== 'updated_date'&& header.value !=='sent_at').map(header => header.value); 
      for (let i = 0; i < searchColumns.length; i++) {
        const column = searchColumns[i];
        if (item[column] && item[column].toString().toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
    }    
    return false;
    },
    row(e) {
      this.$emit("click:row", e);
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer tbody tr :hover {
  cursor: pointer;
}
:deep(.v-data-table__th) {
  white-space : nowrap;
}
:deep(th span) {
  white-space: pre-wrap;
}
</style>