const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/index.vue"),
    meta: { title: "Login - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/active-login",
    name: "ActiveLogin",
    component: () => import("@/views/ActiveLogin/index.vue"),
    meta: { title: "Active Login - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword/index.vue"),
    meta: { title: "Forgot Password - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword/index.vue"),
    meta: { title: "Reset Password - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/send-mail-signup",
    name: "SignUpLink",
    component: () => import("@/views/SignUpLink/index.vue"),
    meta: { title: "Send mail sign up - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/sign-up/:token/:email",
    name: "SignUp",
    component: () => import("@/views/SignUp/index.vue"),
    meta: { title: "SignUp - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/sign-up-new-share/:token",
    name: "SignUpNewShare",
    component: () => import("@/views/SignUpNewShare/index.vue"),
    meta: { title: "SignUp - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/sign-up-new-freeshare/:token/:email",
    name: "SignUpNewFreeShare",
    component: () => import("@/views/SignUp/index.vue"),
    meta: { title: "SignUp - PIN to GAMES", layout: "base-layout" },
  },
  {
    path: "/initial-setting",
    name: "InitialSetting",
    component: () => import("@/views/InitialSetting"),
    meta: {
      title: `初期設定 - PIN to GAMES`,
      layout: "none-layout",
      breadCrumb: [
        {
          text: "初期設定",
        },
      ],
    },
  },
  { path: "/page-not-found", 
    name:"PageNotFound",
    component: () => import("@/components/PageNotFound"),
    meta: {
    layout: "base-layout",
    }, 
  },
  { path: "/page-server-error", 
    name:"PageServerError",
    component: () => import("@/components/PageServerError"),
    meta: {
    layout: "base-layout",
    }, 
  },
  { path: "/:pathMatch(.*)*", 
    component: () => import("@/components/PageNotFound"),
    meta: {
    layout: "base-layout",
    }, 
  },
];

export default routes;
