<template>
  <v-app>
    <v-app-bar app class="elevation-0">
      <div class="d-flex justify-center w-100">
        <div class="header-icon">
          <v-img :src="require('@/assets/logo-pinto-game.svg')" class="my-3" />
        </div>
      </div>
    </v-app-bar>

    <v-main class="bg-imge-main pt-16">
      <div class="pg-containner px-4 pt-3 pb-10">
        <p-breadcrumbs />
        <slot />
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "NoneLayout",
};
</script>

<style lang="scss" scoped>
body {
  .header-icon {
    width: 165px;
  }
  /* .v-toolbar__content {
    justify-content: center !important;
  } */
  .pg-containner {
    max-width: #{868px + 32px};
    margin: 0 auto;
    /* .v-alert__content {
      white-space: pre-line;
    } */
  }
  .v-app-bar {
    border-bottom: 1px solid map-get($grey, "lighten-1");
  }
}
</style>
