import root from './root';
import npcAccountManagement from './npc-account-management';
import npcNotification from './npc-notification';
import npcAdminManagement from './npc-admin-management';
import gameManagement from './game-management';
import notificationManagement from './notification-management';
import userManagement from './user-management';
import ipRestriction from './ip-restrictions';
import accountInfo from './account-info';
import announcementManagement from './announcement-management';
import shareGame from './share-game'

const routes = [
  ...root,

  ...npcAccountManagement,
  
  ...npcNotification,
  
  ...npcAdminManagement,

  ...gameManagement,

  ...notificationManagement,

  ...userManagement,

  ...ipRestriction,

  ...accountInfo,

  ...announcementManagement,

  ...shareGame,
];

export default routes;
