<template>
  <v-textarea
    class="p-textarea"
    v-bind="$attrs"
    :color="color"
  >
    <template v-for="(value, name) in $slots" v-slot:[name]>
      <slot :name="name"></slot>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: "PGTextarea",
  props: {
    color: {
      type: String,
      default: "secondary",
    },
  },
};
</script>
