import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from '@/utils';

const routes = [
  {
    path: '/npc-notification',
    component: Dashboard,
    children: [
      {
        path: "",
        name: "NpcNotification",
        component: () => import('@/views/NPC/NPCNotification/List'),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.notification_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.notification_management"),
            },
          ],
        },
      },
      {
        path: "create",
        name: "CreateNpcNotify",
        component: () => import("@/views/NPC/NPCNotification/CreateAndEdit"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.notification_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.notification_management"),
            },
          ],
        },
      },
      {
        path: "edit/:id",
        name: "EditNpcNotify",
        component: () => import("@/views/NPC/NPCNotification/CreateAndEdit"),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.notification_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb() {
            const id = this.$route.params.id;
            return [
              {
                text: i18n.global.t("nav.notification_management"),
                to: { name: "NpcNotification" },
              },
              {
                text: `${i18n.global.t("label.id")}: ${id}`,
              },
            ];
          },
        },
      },
    ],
  },
];

export default routes;
