<template>
  <div>
    <p-breadcrumb-game
      :id="id"
      :status="gameStatus"
      :category="gameCategory"
      :number_of_published_url="number_of_published_url"
      :name="gameName"
      class="mb-1"
    />
    <slot></slot>
  </div>
</template>

<script>
import { gameStore, mainStore } from "@/store";
import { mapState } from "pinia";
import { CONSTANTS } from "@/utils";
import { auth } from "@/utils";
export default {
  name: "GameDetailLayout",
  setup() {
    const storeGame = gameStore();
    const storeMain = mainStore();
    return {
      storeGame,
      storeMain,
    };
  },
  data() {
    return {
      id: null,
      gameName: "",
      gameCategory: 1,
      gameStatus: CONSTANTS.PERIOD_STATUS.PRIVATE,
      number_of_published_url: 0,
      // logo: null
    };
  },
  computed: {
    ...mapState(gameStore, ["success", "message", "datas", "gameDetail"]),
    companyId() {
      return auth()?.company.company_id;
    },
  },
  watch: {
    "$route.meta": {
      handler: function (meta) {
        this.show = !meta.gameSetting;
      },
      deep: true,
      immediate: true,
    },
    gameDetail() {
      this.id = this.gameDetail?.id;
      this.gameName = this.gameDetail?.name;
      this.gameCategory = this.gameDetail?.category_game;
      this.gameStatus = this.gameDetail?.status;
      this.number_of_published_url = this.gameDetail?.number_of_published_url;
      // this.logo = this.gameDetail ? this.gameDetail?.logo_url : null;
    },
  },
  methods: {
    async getGameById() {
      const id = this.$route.params.gameId;
      // await this.storeMain.setOverlay(true);
      await this.storeGame.findById(this.companyId, id);
      // await this.storeMain.setOverlay(false);
    },
  },
  beforeMount() {
    this.getGameById();
  },
};
</script>
