<template>
  <v-row>
    <v-col cols="12" md="2" class="pa-3">
      <v-card>
        <v-list
          class="pa-2"
          value
          nav
          density="default"
          color="primary"
          v-model:selected="selectedItem"
        >
          <v-list-item
            :class="[item.disabled ? 'disabled_color_text' : '', 'text-subtitle-1', 'font-weight-regular', 'px-2']"
            density="compact"
            v-for="(item, i) in items"
            :key="i"
            :to="item.path"
            :title="item.title"
            exact
            :disabled="item.disabled"
          />
        </v-list>
      </v-card>
    </v-col>

    <v-col cols="12" md="10" class="pa-3">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
import { CONSTANTS } from "@/utils";
export default {
  name: "GameLayout",
  computed: {
     authData() {
      const store = localStorage.getItem(CONSTANTS.AUTH_STORAGE);
      return store ? JSON.parse(store) : null;
    },
    isSharePlan() {
      return this.authData?.company.usage_plan === CONSTANTS.PLAN_TYPE.FREE_SHARE;
    },
    items(){
      return [
        { title: this.$t("title.game_list"), path: "/game-management", disabled: false, },
        {
          title: this.$t("title.share_game"),
          path: "/game-management/share-game",
          disabled: false,
        },
        {
          title: this.$t("title.organization_logo"),
          path: "/game-management/logo-management",
          disabled: false,
        },
        {
          title: this.$t("title.avatar_management"),
          path: "/game-management/avatar-management",
          disabled: this.isSharePlan,
        },
        {
          title: this.$t("title.email_registration"),
          path: "/game-management/email-management",
          disabled: false,
        },
        {
          title: this.$t("title.public_url_list"),
          path: "/game-management/url-management",
          disabled: false,
        },
      ]
    },
  },
  data() {
    return {
      selectedItem: [0],

    };
  },
};
</script>

<style lang="scss" scoped>
.c-active {
  /* color: #dc2828; */
  color: blue;
  &::before {
    opacity: 0.12 !important;
    /* background-color: #dc2828; */
    background-color: yellowgreen;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .v-list-item__title {
    /* color: #dc2828; */
    color: purple;
  }
}
.disabled_color_text {
  color: var(--opacity-text--primary, rgba(0, 0, 0, 0.87));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.2;
}
:deep(.v-list-item-title){
  margin-left: 8px;
}
</style>
