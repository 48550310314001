import i18n from "@/i18n";
import Dashboard from "@/components/layouts/Dashboard";
import { CONSTANTS } from '@/utils';

const routes = [
  {
    path: '/npc-account-management',
    component: Dashboard,
    children: [
      {
        path: "",
        name: "NPCAccountManagement",
        component: () => import('@/views/NPC/NPCAccountManagement/List'),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.npc_account_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.global.t("nav.npc_account_management"),
            },
          ],
        },
      },
      {
        path: "edit/:company_id",
        name: "DetailAndEditNpcAccount",
        component: () => import('@/views/NPC/NPCAccountManagement/DetailAndEdit'),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.npc_account_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb() {
            const company_id = this.$route.params.company_id;
            return [
              {
                text: i18n.global.t("nav.npc_account_management"),
                to: { name: "NPCAccountManagement" },
              },
              {
                text: `${i18n.global.t("label.company_id")}: ${company_id}`,
              },
            ];
          },
        },
      },
      {
        path: "payment-history/:company_id",
        name: "NpcPaymentHistory",
        component: () => import('@/views/NPC/NPCAccountManagement/PaymentHistory'),
        meta: {
          roles: [CONSTANTS.TYPE_ROLE.OWNER, CONSTANTS.TYPE_ROLE.ADMIN],
          title: `${i18n.global.t("nav.npc_account_management")} - PIN to GAMES`,
          requiresAuth: true,
          breadCrumb() {
            const company_id = this.$route.params.company_id;
            return [
              {
                text: i18n.global.t("nav.npc_account_management"),
                to: { name: "NPCAccountManagement" },
              },
              {
                text: `${i18n.global.t("label.company_id")}: ${company_id}`,
                to: { name: "DetailAndEditNpcAccount" },
              },
              {
                text: `${i18n.global.t("text.billing_history")}`,
              },
            ];
          },
        },
      },
    ],
  },
];

export default routes;
