import { defineStore } from "pinia";
import { API, URLS, handleGetMessageError } from "@/utils";
export const npcShareGameStore = defineStore("npcShareGameStore", {
  state: () => ({
    success: true,
    message: "",
    listGameShare: [],
    datas: [],
    checkAvailable: false,
    error: null,
    listError: [],
  }),

  actions: {
    async getListCompany() {
      await API.requestServer
        .get(`${URLS.NPC.LIST_COMPANY}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.datas = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },

    async getListGameShare() {
      await API.requestServer
        .get(`${URLS.NPC.LIST_SHARE_GAME}`)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listGameShare = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async checkCreateShareGame(request) {
      await API.requestServer
        .post(`${URLS.NPC.CHECK_CREATE_SHARE}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.checkAvailable = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },

    async createShareGame(request) {
      await API.requestServer
        .post(`${URLS.NPC.CREATE_SHARE_GAME}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.datas = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async createShareGameExistAccount(request) {
      await API.requestServer
        .post(`${URLS.NPC.CREATE_SHARE_GAME_EXIST}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.datas = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
    async updateShareGame(request) {
      await API.requestServer
        .patch(`${URLS.NPC.UPDATE_SHARE_GAME}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.datas = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
  
    async deleteShareGame(id) {
      await API.requestServer
        .post(`${URLS.NPC.REMOVE_SHARE_GAME}`, { id: id })
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.datas = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },

    async importGameSharingList(request) {
      await API.requestServer
        .post(`${URLS.NPC.IMPORT_SHARE_GAME}`, request)
        .then((response) => {
          const { data } = response;
          this.success = true;
          this.listError = data.data;
        })
        .catch((error) => {
          this.success = false;
          this.message = handleGetMessageError(error);
        });
    },
  },
});
