<template>
  <div>
    <v-breadcrumbs
      :items="breadCrumbs"
      v-bind="$attrs"
      color="primary"
      class="p-breadcrumbs px-0 py-2 text-subtitle-2"
    >
      <template v-slot:item="{ item }" v-if="items.length <= 0 && !hasDefaultSlot">
        <v-breadcrumbs-item
          @click="item.to ? goTo(item.to) : ''"
          class="text-subtitle-2 crumb-item"
          :disabled="breadCrumbs[breadCrumbs.length - 1].text == item.text"
          :style="{ cursor: item.to ? 'pointer' : '' }"
          exact
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { CONSTANTS } from "@/utils";
export default {
  name: "PGBreadcrumbs",
  props: {
    items: { type: Array, default: () => [] },
  },

  computed: {
    breadCrumbs() {
      if (this.items.length > 0) {
        const mapItems = this.items.map((value) => {
          const title = value.text;
          const href = value.to ?? "";
          return {
            ...value,
            title,
            href,
          };
        });
        return mapItems;
      }
      // if (this.items.length > 0) {
      //   return this.items;
      // }
      const routeBreadCrumb = this.$route.meta.breadCrumb;
      if (typeof routeBreadCrumb === "function") {
        const data = routeBreadCrumb.call(this, this.$route);
        if (
          data[0].text === this.$t("nav.user_management") && (!this.company_situation || this.checkError)
        ) {
          data[0].to = null;
        }
        return data;
      }
      return routeBreadCrumb;
    },

    hasDefaultSlot() {
      return !!this.$slots["default"];
    },

    authData() {
      const store = localStorage.getItem(CONSTANTS.AUTH_STORAGE);
      return store ? JSON.parse(store) : null;
    },

    company_situation() {
      return this.authData?.company.usage_situation;
    },

    checkError() {
      const expired = this.authData?.company.alertError?.expired;
      const card_null = this.authData?.company.alertError?.card_null;
      const stop_using = this.authData?.company?.stop_using;
      const storage = this.authData?.company?.used_capacity.percent;
      const card_expired = this.authData?.company.alertError?.card_expired;
      const bandwidth =
        this.authData?.company.used_monthly_data_transfer_volume.percent;
      return (
        storage >= 100 ||
        bandwidth >= 100 ||
        card_null ||
        card_expired ||
        expired ||
        stop_using
      );
    },
  },
  methods: {
    goTo (payload) {
      this.$router.push(payload)
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-breadcrumbs-item:first-child) {
  padding-left: 0 !important;
}
:deep(.v-breadcrumbs-item:last-child) {
  color: rgba(map-get($shades, "black"), 1) !important;
}
:deep(.v-breadcrumbs-divider) {
  color: #9f8f0c;
}
</style>
