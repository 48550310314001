<template>
  <v-app class="bg-imge-main">
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MainLayout",
};
</script>
