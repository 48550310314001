<template>
  <div
    class="d-flex"
    @click.stop=""
    v-bind:style="{ pointerEvents: pointer ? 'none' : '' }"
  >
    <a
      class="d-inline-block text-truncate text-grey-darken-1 textUrl"
      style="max-width: 400px"
      ref="myLink"
      :href="urlLink"
      target="_blank"
    >
      {{ urlLink }}
    </a>

    <input type="hidden" id="testing-code" :model-value="urlLink" />

    <div class="text-error cursor-pointer mx-3">
      <div class="d-flex cursor-pointer" style="width: fit-content">
        <div @click="copyURL" class="primary--text">
          <v-icon color="primary" class="pr-2" size="small">
            mdi-link-variant
          </v-icon>
          {{ $t("text.copy_url") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gameStore, mainStore } from "@/store";
import { mapState } from "pinia";
// ToanDP 21/03/2023 MOD --->>
import { auth, encryptedUrl } from "@/utils";
// ToanDP 21/03/2023 MOD <<---
export default {
  name: "PublicUrl",
  setup() {
    const storeMain = mainStore();
    const storeGame = gameStore();
    return {
      storeMain,
      storeGame,
    };
  },
  props: {
    url: { type: String, default: "" },
    stage: { type: String, default: "" },
    pointer: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(gameStore, ["gameDetail"]),
    companyId() {
      return auth()?.company.company_id;
    },
    urlLink() {
      // ToanDP 21/3/2023 encode url --->>
      const url = `${this.companyId}_normal&${this.gameDetail?.category_game}_${this.gameDetail?.id}&${this.stage}`;
      return encryptedUrl(url);
      // ToanDP 21/3/2023 encode url <<---
    },
  },
  methods: {
    copyURL() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        document.execCommand("copy");
        this.storeMain.snackbar({
          text: "URLをクリップボードにコピーしました。",
          show: true,
          color: "success",
        });
      } catch (err) {
        this.storeMain.snackbar({
          text: err,
          show: true,
          color: "error",
        });
      }
      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style>
.textUrl {
  text-decoration: none;
}
</style>
