export const URLS = {
  BASE_URL_SERVER: `${process.env.VUE_APP_BASE_API}`,
  LOGIN: "auth/login",
  ACTIVE_LOGIN: "auth/active-login",
  TOW_FACTOR_VERY: "auth/two-factor-verification",
  LIST_MAINTAIN: "auth/get-list-maintain",
  LOGOUT: "auth/logout",
  AUTH_ME: "auth/me",
  GET_USER_LOGIN: "auth/get-user-login",
  UPDATE_USER_LOGIN: "auth/update-user-login",
  GET_DROPDOWN_USER_LIST: "auth/get-dropdown-userlist",
  SET_DROPDOWN_USER_LIST: "auth/set-dropdown-userlist",
  DELETE_DROPDOWN_USER_LIST: "auth/delete-dropdown-userlist",
  FORGOT_PASS: "auth/forgot-password",
  RESET_PASS: "auth/reset-password",
  SEND_MAIL_SIGNUP: "auth/invite-create-account",
  SEND_MAIL_SIGNUP_SHARE: "auth/invite-create-share-account",
  GENERATE_NEW_TOKEN: "auth/generate-new-token",
  CREATE_ACCOUNT: "auth/create-account",
  CREATE_SHARE_ACCOUNT: "auth/create-share-account",
  CHECK_ADMIN_USER: "auth/check-admin-user",
  CHECK_AUTO_ADMIN_USER: "auth/check-auto-admin-user",
  EDIT_USER: "user/edit",
  USER: "user",
  ONWER: "owner",
  ONWER_SHARE: "owner-share",
  INITIAL_SETTING: "auth/initial-setting",
  UPDATE_ACCOUNT: "auth/update-account",
  IP_RESTRICTIONS_GET_ALL: "ip-restrictions/get-all",
  IP_RESTRICTIONS_CREATE: "ip-restrictions/create",
  IP_RESTRICTIONS_EDIT: "ip-restrictions/edit/",
  IP_RESTRICTIONS: "ip-restrictions",
  IP_RESTRICTIONS_IMPORT: "ip-restrictions/import",
  LOGIN_RATE: "login-rate",
  LOGIN_RATE_LAST: "login-rate/last-date",
  GAME: "game",
  DOSURU_LISTQUESTIONANSWER: "dousuru-listquestionsanswer",
  GET_ANSWER_ID: "getAnswerId",
  SET_BEST_ANSWER: "set-best-answer",
  GAME_NPC_AUTOCOMPLETE: "game/npc-autocomplete",
  CLONE: "clone",
  GAME_TREEVIEW: "treeview",
  NOTIFICATION: "notification",
  COMPANY: "company",
  COMPANY_UPLOAD_LOGO: "company/upload-logo",
  TOTAL_USED_CAPACITY: "total-used-capacity",
  AVATAR: "avatar",
  IMPORT: "import",
  UPDATE_CARD: "stripe/card/edit",
  PAYMENT: "payment",
  PUBLIC_URL: "public-url",
  PUBLIC_URL_MANAGEMENT: "public-url-management",
  COURSE: "course",
  COURSE_ORDER: "course-order",
  GAME_ORDER: "game-order",
  STAGE: "stage",
  CREATE_CARTDE_GO: "create/cartdego",
  EDIT_CARTDE_GO: "edit/cartdego",
  BRANDPITTAN: "brandpittan",
  DONMEMO: "donmemo",
  PROBLEM: "problem",
  AREA: "area",
  ORDER: "order",
  GENERATE: "generate",
  REGENERATE: "regenerate",
  LIST_COMPANY_SHARE_AVAILABLE: "list-company-share-available",
  LIST_COMPANY_CAN_SHARE: "list-company-can-share",
  LIST_GAME_SHARE_AVAILABLE: "list-game-share-available",
  LIST_GAME_SHARE_TO_OTHER_COMPANY: "list-company-share-game",
  LIST_MANAGE_GAME_SHARE: "list-manage-game-share",
  CREATE_GAME_SHARE_URL: "create-game-share-url",
  CHECK_GAME_SHARE_URL: "check-game-share-url",
  GET_GAME_SHARE_URL: "get-game-share-url",
  LIST_GAME_SHARE_FROM_OTHER_COMPANY: "list-game-share-outside",
  CREATE_NEW_SHARE_GAME: "create-new-share-game",
  GET_SHARE_GAME_DETAILS: "get-share-game",
  EDIT_GAME_SHARE: "edit-game-share",
  DELETE_GAME_SHARE: "delete-game-share",
  withdrawal_application: "withdrawal-application",
  SETTING: "setting",
  UPDATE_SHARE_GAME_COMPANY: "update-game-share-company",
  CREATE_GROUP_USER: "create-group-user",
  GROUP: "group",
  UPDATE_GROUP: "update-group",
  UPDATE_GAME_LIST: "update-game-list",
  NPC: {
    LIST_ACCOUNT: "npc/list-account",
    ACCOUNT_DETAIL: "npc/account-detail",
    CREATE_ACCOUNT: "npc/create-account",
    UPDATE_ACCOUNT: "npc/update-account",
    DELETE_ACCOUNT: "npc/delete-account",
    PAYMENT_HISTORY: "npc/payment-history",
    CHECK_MAIL_USER: "npc/check-mail",

    LIST_LOGIN_RATE: "npc/list-login-rate",

    LIST_NOTIFICATION: "npc/list-notification",
    NOTIFICATION_DETAIL: "npc/notification-detail",
    CREATE_NOTIFICATION: "npc/create-notification",
    UPDATE_NOTIFICATION: "npc/update-notification",
    DELETE_NOTIFICATION: "npc/delete-notification",

    LIST_ADMIN: "npc/list-admin",
    ADMIN_DETAIL: "npc/admin-detail",
    CREATE_ADMIN: "npc/create-admin",
    UPDATE_ADMIN: "npc/update-admin",
    DELETE_ADMIN: "npc/delete-admin",
    ADMIN_AUTOCOMPLETE: "npc/admin-autocomplete",
    LIST_COMPANY: "npc/list-company-share",
    CHECK_CREATE_SHARE: "npc/check-create-share",
    CREATE_SHARE_GAME: "npc/create-game-share",
    CREATE_SHARE_GAME_EXIST: "npc/create-game-share-exist",
    REMOVE_SHARE_GAME: "npc/remove-game-share",
    LIST_SHARE_GAME: "npc/list-game-share",
    UPDATE_SHARE_GAME: "npc/update-game-share",
    IMPORT_SHARE_GAME: "npc/import-game-share",
  },
  ANNOUNCEMENT: "announcement",
  TEMPLATE_IMAGE: "list-template-image",
  SCORE_HISTORY: "score-history",
  HISTORY_SHARE_RANKING: "history-share-ranking",
  HISTORY_ZIP: "download-all",
};
