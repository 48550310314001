<template>
  <div class="p-breadcrumb-game">
    <v-alert
      v-model="importError"
      type="error"
      width="100%"
      icon="mdi-alert-octagon-outline"
      closable
      close-icon="mdi-close"
    >
      {{ message }}
    </v-alert>

    <PGHeader class="mb-4">
      <template #default>
        <div class="p-breadcrumb-game__game-title d-flex flex-gap-2">
          <v-img
            max-height="32"
            max-width="32"
            width="32"
            height="32"
            :src="icon ? icon : require(`@/assets/images/category/${category}.png`)"
          />
          <h1 class="text-h5 p-breadcrumb-game__game-title-name">{{ name }}</h1>
        </div>
      </template>

      <template #info>
        <div class="d-flex flex-gap-6">
          <div class="d-flex flex-gap-2 align-center">
            <p-game-status :status="status" :id="id" />
            <!-- <p-url-number :count="number_of_published_url" :id="id" /> -->
          </div>

          <div class="d-flex flex-gap-2">
            <p-icon-btn
              bottom
              @click="toBasicSetting"
              :tooltip-text="$t('tooltip.edit')"
              tooltip-icon="mdi-pencil"
              v-if="editable"
            />

            <p-icon-btn
              bottom
              link
              @click="redirect(webGL)"
              target="_blank"
              :tooltip-text="$t('tooltip.preview_web_version')"
              tooltip-icon="mdi-play"
            />
          </div>
        </div>
      </template>

      <template #actions>
        <div class="d-flex flex-gap-2" v-if="editable">
          <v-btn
            color="primary"
            @click="$refs.uploader.click()"
            variant="text"
            :loading="importLoading"
            :disabled="importLoading"
          >
            <v-icon location="left"> mdi-tray-arrow-up </v-icon>
            {{ $t("text.import") }}
          </v-btn>

          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".xlsx"
            @change="onFileChanged"
          />

          <v-btn
            color="primary"
            @click="exportExcel"
            variant="text"
            :loading="exportLoading"
            :disabled="exportLoading"
          >
            <v-icon location="left"> mdi-tray-arrow-down </v-icon>
            {{ $t("text.export") }}
          </v-btn>
        </div>
      </template>
    </PGHeader>
  </div>
</template>

<script>
import { gameStore } from "@/store";
import { auth, CONSTANTS, exportAOA, exportXLSX } from "@/utils";
import { mapState } from "pinia";
import { defineAsyncComponent } from "vue";
export default {
  name: "PGBreadcrumbGame",
  components: {
    PGHeader: defineAsyncComponent(() => import("@/components/PGHeader")),
  },
  props: {
    category: { type: Number, default: 1 },
    id: { type: String, default: null },
    status: { type: Number, default: CONSTANTS.PERIOD_STATUS.PRIVATE },
    number_of_published_url: { type: Number, default: 0 },
    name: { type: String, default: null },
    editable: { type: Boolean, default: true },
    // logo: { type: String, default: null },
  },
  setup() {
    const storeGame = gameStore();
    return { storeGame };
  },
  data() {
    return {
      importLoading: false,
      importError: false,
      exportLoading: false,
      webGL: process.env.VUE_APP_WEBGL,
      icon: '',
    };
  },
  watch: {
    gameDetail() { 
      this.icon = this.gameDetail?.icon_url;
    }
  },
  computed: {
    ...mapState(gameStore, ["success", "message", "gameDetail"]),
    companyId() {
      return auth()?.company.company_id;
    },
  },
  methods: {
    redirect(link) {
        window.open(link, '_blank');
    },
    async onFileChanged(e) {
      this.importLoading = true;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("category", this.$props.category);
      await this.storeGame.importExcel(
        this.companyId,
        this.$props.id,
        formData
      );
      if (this.success) {
        // await this.getCourseList();
        window.location.reload();
      }
      this.importError = !this.success;
      this.$refs.uploader.value = "";
      this.importLoading = false;
    },
    async exportExcel() {
      this.exportLoading = true;
      const res = await this.storeGame.exportExcel(
        this.companyId,
        this.$props.id
      );
      const categoryMap = {
        [CONSTANTS.CATEGORY_GAME_VALUE.DORAKUE]: this.exportDorakue,
        [CONSTANTS.CATEGORY_GAME_VALUE.CARTDE_GO]: this.exportCartdeGo,
        [CONSTANTS.CATEGORY_GAME_VALUE.POIPOI]: this.exportPoiPoi,
        [CONSTANTS.CATEGORY_GAME_VALUE.DONMEMO]: this.exportDomemo,
        [CONSTANTS.CATEGORY_GAME_VALUE.BRAND_PITTAN]: this.exportBrandPittan,
      };

      if (res?.data && categoryMap[this.$props.category]) {
        categoryMap[this.$props.category](res.data);
      } else {
        exportXLSX("ゲームインポート", res.data, "game list");
      }
      this.exportLoading = false;
    },
    exportDorakue(data = []) {
      const items = [];
      items.push(CONSTANTS.GAME_DORAKUE_EXPORT_HEADER);
      data.forEach((e) => {
        items.push([
          e.areaName ?? "",
          e.areaOrder ?? "",
          e.areaStatus ?? "",
          e.areaPublicStartDate ?? "",
          e.areaPublicStartTime ?? "",
          e.areaPublicEndDate ?? "",
          e.areaPublicEndTime ?? "",
          e.courseName ?? "",
          e.courseOrder ?? "",
          e.courseStatus ?? "",
          e.coursePublicStartDate ?? "",
          e.coursePublicStartTime ?? "",
          e.coursePublicEndDate ?? "",
          e.coursePublicEndTime ?? "",
          e.courseOpeningCondition ?? "",
          e.coursePublicUrlFlg ?? "",
          e.stageName ?? "",
          e.stageOrder ?? "",
          e.stageOpeningCondition ?? "",
          e.stageStatus ?? "",
          e.stagePublicStartDate ?? "",
          e.stagePublicStartTime ?? "",
          e.stagePublicEndDate ?? "",
          e.stagePublicEndTime ?? "",
          e.stagePublicUrlFlg ?? "",
          e.problemTitle ?? "",
          e.problemOrder ?? "",
          e.problemStatus ?? "",
          e.problemPublicStartDate ?? "",
          e.problemPublicStartTime ?? "",
          e.problemPublicEndDate ?? "",
          e.problemPublicEndTime ?? "",
          e.problemStatement ?? "",
          e.choise1 ?? "",
          e.choise1Title ?? "",
          e.choise1Commentary ?? "",
          e.choise1IsAnswer ?? "",
          e.choise2 ?? "",
          e.choise2Title ?? "",
          e.choise2Commentary ?? "",
          e.choise2IsAnswer ?? "",
          e.choise3 ?? "",
          e.choise3Title ?? "",
          e.choise3Commentary ?? "",
          e.choise3IsAnswer ?? "",
          // e.coursePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.coursePublicUrl}` : "",
          // e.stagePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.stagePublicUrl}` : "",
        ]);
      });
      exportAOA(
        `${this.name} - ${this.id}`,
        items,
        this.name,
        CONSTANTS.QUEST_CRAFT_EXPALIN
      );
    },

    exportCartdeGo(data = []) {
      const items = [];
      items.push(CONSTANTS.GAME_CARTDE_GO_HEADER);
      data.forEach((e) => {
        items.push([
          e.courseName ?? "",
          e.courseOrder ?? "",
          e.courseStatus ?? "",
          e.coursePublicStartDate ?? "",
          e.coursePublicStartTime ?? "",
          e.coursePublicEndDate ?? "",
          e.coursePublicEndTime ?? "",
          e.courseOpeningCondition ?? "",
          e.coursePublicUrlFlg ?? "",
          e.stageName ?? "",
          e.stageOpeningCondition ?? "",
          e.stageOrder ?? "",
          e.stageStatus ?? "",
          e.stagePublicStartDate ?? "",
          e.stagePublicStartTime ?? "",
          e.stagePublicEndDate ?? "",
          e.stagePublicEndTime ?? "",
          e.stagePublicUrlFlg ?? "",
          e.problemOrder ?? "",
          e.problemStatus ?? "",
          e.problemPublicStartDate ?? "",
          e.problemPublicStartTime ?? "",
          e.problemPublicEndDate ?? "",
          e.problemPublicEndTime ?? "",
          e.problemStatement ?? "",
          e.choise1Title ?? "",
          e.choise1Commentary ?? "",
          e.choise1IsAnswer ?? "",
          e.choise2Title ?? "",
          e.choise2Commentary ?? "",
          e.choise2IsAnswer ?? "",

          // e.coursePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.coursePublicUrl}` : "",
          // e.stagePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.stagePublicUrl}` : "",
        ]);
      });
      exportAOA(
        `${this.name} - ${this.id}`,
        items,
        this.name,
        CONSTANTS.CARTDE_GO_EXPLAIN
      );
    },

    exportPoiPoi(data = []) {
      const items = [];
      const headers = CONSTANTS.GAME_POIPOI_HEADER;
      const choiceLength = data.pop();
      for (let i = 1; i <= choiceLength; i++) {
        headers.push(
          `【問題】解説見出し${i}[20]`,
          `【問題】解説文${i}[50]`,
          `【問題】不正解/正解${i}(0/1)`
        );
      }
      // headers.push(...CONSTANTS.END_URL_HEADER)
      items.push(headers);

      data.forEach((e) => {
        const choices = [];
        for (let i = 1; i <= choiceLength; i++) {
          const title = e[`choise${i}Title`] || "";
          const commentary = e[`choise${i}Commentary`] || "";
          const isAnswer =
            e[`choise${i}IsAnswer`] !== undefined
              ? e[`choise${i}IsAnswer`]
              : "";

          choices.push(title, commentary, isAnswer);
        }
        items.push([
          e.courseName ?? "",
          e.courseOrder ?? "",
          e.courseStatus ?? "",
          e.coursePublicStartDate ?? "",
          e.coursePublicStartTime ?? "",
          e.coursePublicEndDate ?? "",
          e.coursePublicEndTime ?? "",
          e.courseOpeningCondition ?? "",
          e.coursePublicUrlFlg ?? "",

          e.stageOpeningCondition ?? "",
          e.stageOrder ?? "",
          e.stageStatus ?? "",
          e.stagePublicStartDate ?? "",
          e.stagePublicStartTime ?? "",
          e.stagePublicEndDate ?? "",
          e.stagePublicEndTime ?? "",
          e.stagePublicUrlFlg ?? "",

          e.problemStatement ?? "",
          ...choices,

          // e.coursePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.coursePublicUrl}` : "",
          // e.stagePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.stagePublicUrl}` : "",
        ]);
      });
      exportAOA(
        `${this.name} - ${this.id}`,
        items,
        this.name,
        CONSTANTS.POIPOI_EXPLAIN
      );
    },

    exportDomemo(data = []) {
      const items = [];
      const headers = CONSTANTS.GAME_DOMEMO_HEADER;
      const imageCountLength = data.pop();
      for (let i = 1; i <= imageCountLength; i++) {
        headers.push(`【問題】不正解/正解${i}(0/1)`);
      }
      // headers.push(...CONSTANTS.END_URL_HEADER)
      items.push(headers);

      data.forEach((e) => {
        const images = [];
        for (let i = 1; i <= imageCountLength; i++) {
          const isAnswer = e[`img${i}Answer`];
          images.push(isAnswer !== undefined ? isAnswer : "");
        }
        items.push([
          e.courseName ?? "",
          e.courseOrder ?? "",
          e.courseStatus ?? "",
          e.coursePublicStartDate ?? "",
          e.coursePublicStartTime ?? "",
          e.coursePublicEndDate ?? "",
          e.coursePublicEndTime ?? "",
          e.courseOpeningCondition ?? "",
          e.coursePublicUrlFlg ?? "",

          e.stageName ?? "",
          e.stageOpeningCondition ?? "",
          e.stageOrder ?? "",
          e.stageStatus ?? "",
          e.stagePublicStartDate ?? "",
          e.stagePublicStartTime ?? "",
          e.stagePublicEndDate ?? "",
          e.stagePublicEndTime ?? "",
          e.stagePublicUrlFlg ?? "",
          e.choiceOrder ?? "",
          e.choiceName ?? "",
          e.choiceCommentaryTitle ?? "",
          e.choiceCommentary ?? "",
          ...images,

          // e.coursePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.coursePublicUrl}` : "",
          // e.stagePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.stagePublicUrl}` : "",
        ]);
      });
      exportAOA(
        `${this.name} - ${this.id}`,
        items,
        this.name,
        CONSTANTS.DONMEMO_EXPALIN
      );
    },

    exportBrandPittan(data = []) {
      const items = [];
      const headers = CONSTANTS.GAME_BRAND_PITTAN_HEADER;
      const imageCountLength = data.pop();
      for (let i = 1; i <= imageCountLength; i++) {
        headers.push(
          `【問題】解説見出し${i}[20]`,
          `【問題】解説文${i}[50]`,
          `【問題】不正解/正解${i}(0/1)`
        );
      }
      // headers.push(...CONSTANTS.END_URL_HEADER)
      items.push(headers);

      data.forEach((e) => {
        const images = [];
        for (let i = 1; i <= imageCountLength; i++) {
          const [title, commentary, isAnswer] = [
            e[`img${i}Title`],
            e[`img${i}Commentary`],
            e[`img${i}IsAnswer`],
          ];
          images.push(
            title || "",
            commentary || "",
            isAnswer !== undefined ? isAnswer : ""
          );
        }
        items.push([
          e.courseName ?? "",
          e.courseOrder ?? "",
          e.courseStatus ?? "",
          e.coursePublicStartDate ?? "",
          e.coursePublicStartTime ?? "",
          e.coursePublicEndDate ?? "",
          e.coursePublicEndTime ?? "",
          e.courseOpeningCondition ?? "",
          e.coursePublicUrlFlg ?? "",

          e.stageOpeningCondition ?? "",
          e.stageOrder ?? "",
          e.stageStatus ?? "",
          e.stagePublicStartDate ?? "",
          e.stagePublicStartTime ?? "",
          e.stagePublicEndDate ?? "",
          e.stagePublicEndTime ?? "",
          e.stagePublicUrlFlg ?? "",

          e.problemType ?? "",
          e.problemStatement ?? "",
          e.reelNo ?? "",
          ...images,

          // e.coursePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.coursePublicUrl}` : "",
          // e.stagePublicUrl ? `${process.env.VUE_APP_URL_APP}${e.stagePublicUrl}` : "",
        ]);
      });
      exportAOA(
        `${this.name} - ${this.id}`,
        items,
        this.name,
        CONSTANTS.BRAND_PITTAN_EXPLAIN
      );
    },
    
    toBasicSetting() {
      const currentRoute = this.$router.currentRoute.value;
      if (currentRoute.path.replace(/(.*)\/(.*)$/g, "$2") !== "basic-setting") {
        this.$router.push({
          path: `/game-management/${this.id}/basic-setting`,
        });
      } else {
        location.reload();
      }
    },
  },
  created() {
    this.storeGame.findById(this.companyId, this.$route.params.gameId)
  }
};
</script>

<style lang="scss" scoped>
.p-breadcrumb-game__game-title {
  display: grid !important;
  grid-template-columns: auto 1fr;
}
.p-breadcrumb-game__game-title-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
